import { LinkedComponent } from '@calo/dashboard-types';
import { Dictionary, Nutrition } from '@calo/types';
import { Paper, Table, TableBody, TableContainer, TableRow } from '@mui/material';
import { FoodComponent } from 'lib/interfaces';

import CustomizedFoodComponentRow from './CustomizedFoodComponentRow';
import CustomizedFoodTableHead from 'views/ChefPlayground/ExactFood/CustomizeFoodMacrosPopUp/CustomizedFoodTable/CustomizedFoodTableHead';
import CustomizedFoodTableFooter from 'views/ChefPlayground/ExactFood/CustomizeFoodMacrosPopUp/CustomizedFoodTable/CustomizedFoodTableFooter';

interface CustomizedFoodTableProps {
  isChild: boolean;
  isDisabled: boolean;
  selectedComponents: LinkedComponent[];
  allComponents: Dictionary<FoodComponent>;
  onChange: (rows: LinkedComponent[]) => void;
  isPreBuildCustom?: boolean;
}

const CustomizedFoodTable = (props: CustomizedFoodTableProps) => {
  const { isDisabled, isChild, selectedComponents, allComponents, onChange, isPreBuildCustom } = props;

  return (
    <Paper sx={{ width: '98%', m: 2, mt: 0, boxShadow: 'none' }}>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <CustomizedFoodTableHead isPreBuildCustom={isPreBuildCustom} />
          <TableBody>
            {selectedComponents.map((row, index) => (
              <TableRow key={row.id}>
                <CustomizedFoodComponentRow
                  isDisabled={isDisabled}
                  childComp={isChild}
                  selectedComponents={selectedComponents}
                  onChange={onChange}
                  allComponents={allComponents}
                  component={row}
                  isPreBuildCustom={isPreBuildCustom}
                  index={index}
                />
              </TableRow>
            ))}
          </TableBody>
          <CustomizedFoodTableFooter
            isPreBuildCustom={isPreBuildCustom}
            selectedComponents={selectedComponents}
            allComponents={allComponents}
            Nutrition={Nutrition}
            isChild={isChild}
          />
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default CustomizedFoodTable;
