import { Subscription } from '@calo/dashboard-types';
import { FoodPreference, IngredientCategory } from '@calo/types';
import { Box, MenuItem, Stack, TextField } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { startCase } from 'lodash-es';
import FoodRestrictionItem from './FoodRestrictionItem';

interface DislikedIngredientsProps {
  headerFoodRestrictionOpen: boolean;
  dislikedIngredientsCategory: IngredientCategory[];
  setDislikedIngredientsCategory: React.Dispatch<React.SetStateAction<IngredientCategory[]>>;
  selectedCustomer: Subscription & { foodPreference?: FoodPreference };
  handleChangeDislikedIngCat: (data: IngredientCategory[]) => void;
}

const DislikedIngredients = ({
  headerFoodRestrictionOpen,
  dislikedIngredientsCategory,
  setDislikedIngredientsCategory,
  selectedCustomer,
  handleChangeDislikedIngCat
}: DislikedIngredientsProps) => {
  return (
    <Box
      style={{
        marginTop: '4px',
        display: headerFoodRestrictionOpen ? 'flex' : 'none',
        flexDirection: 'column'
      }}
    >
      <Stack>
        <TextField
          // @ts-ignore
          select
          placeholder={'Search Ingredients'}
          label="Disliked Ingredients"
          value={dislikedIngredientsCategory}
          SelectProps={{
            multiple: true,
            value: dislikedIngredientsCategory || [],
            onChange: (data) => handleChangeDislikedIngCat(data.target.value as IngredientCategory[])
          }}
          InputProps={{ inputProps: { style: { borderRadius: 8, width: 'full' } }, style: { borderRadius: 8 } }}
        >
          {Object.values(IngredientCategory)
            .filter((r) => (dislikedIngredientsCategory ? !dislikedIngredientsCategory.includes(r) : r))
            .map((row) => (
              <MenuItem key={row} value={row}>
                {startCase(row)}
              </MenuItem>
            ))}
        </TextField>
        <Box
          sx={{
            paddingY: 1,
            paddingX: '4px',
            display: selectedCustomer.foodPreference?.dislikedIngredientsCategory ? 'relative' : 'none'
          }}
        >
          <Stack
            sx={{
              border: 0,
              borderColor: caloTheme.palette.neutral100,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            {dislikedIngredientsCategory &&
              dislikedIngredientsCategory.length > 0 &&
              dislikedIngredientsCategory.map((allergicIng: any) => {
                return FoodRestrictionItem({
                  keyProp: allergicIng,
                  text: `${startCase(allergicIng.toLowerCase())}`,
                  onClick: () => (
                    setDislikedIngredientsCategory(() => dislikedIngredientsCategory.filter((wi) => wi !== allergicIng)),
                    handleChangeDislikedIngCat(dislikedIngredientsCategory.filter((wi) => wi !== allergicIng) || [])
                  )
                });
              })}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default DislikedIngredients;
