import { MenuFoodComponent } from '@calo/dashboard-types';
import { Brand, Country, Dictionary, Macros, Nutrition } from '@calo/types';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { createPrototypeFood, getListWithParams } from 'actions/index';
import { caloTheme } from 'assets/images/theme/calo';
import { getOrderedComponents } from 'components/Sections/helpers';
import { Food, FoodComponent } from 'lib/interfaces';
import { keyBy, omit, round, sumBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getCreateCustomizeFoodFormInitialValues, useCreateCustomizeFoodForm } from '../CreateCustomizeFoodForm';
import CustomizedFoodTable from '../CustomizedFoodTable';
import { Kitchen as KitchenType } from '@calo/types/dist/enums';
import { ComponentService } from '@calo/services';

interface CustomizeFoodMacrosResultsCardProps {
  newMacros?: Macros;
  food: Food;
  positionIndex: number;
  selectedFood: Food;
  setSelectedFood: (food: Food) => void;
  foodWithAllSizes: Food[];
  setFoodWithAllSizes: (food: Food[]) => void;
  selectedSize: string;
  setSelectedSize: (size: string) => void;
  close: () => void;
  submitForm: () => void;
}

const CustomizeFoodMacrosResultsCard = ({
  food,
  selectedFood,
  setSelectedFood,
  foodWithAllSizes,
  setFoodWithAllSizes,
  selectedSize,
  setSelectedSize,
  close,
  submitForm
}: CustomizeFoodMacrosResultsCardProps) => {
  const selectedFoodCompIds = useMemo(() => food?.components?.map((comp) => comp.id) ?? [], [food]);
  const [selectedFoodComponents, setSelectedFoodComponents] = useState<FoodComponent[]>([]);

  const { isLoading: isLoadingComponent } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        limit: selectedFoodCompIds.length,
        filters: {
          withChildComponents: true,
          ids: selectedFoodCompIds,
          brand: Brand.CALO,
          kitchen: food?.kitchen
        }
      }
    ],
    getListWithParams,
    {
      suspense: false,
      keepPreviousData: true,
      enabled: !!selectedFoodCompIds.length,
      onSuccess: (data) => {
        if (data.data) {
          setSelectedFoodComponents(data.data);
        }
      }
    }
  );

  const [allComponents, setAllComponents] = useState<Dictionary<FoodComponent>>({});
  const keyedSelectedComponents = useMemo(() => keyBy(selectedFoodComponents, 'id'), [selectedFoodComponents]);

  const { mutateAsync: createPrototypeFoodMutation, isLoading: isLoadingPrototypeFood } = useMutation(createPrototypeFood);

  const { values, setValues, setFieldValue } = useCreateCustomizeFoodForm(food);

  useEffect(() => {
    setAllComponents((prev) => {
      const newComponents = {};
      for (const id in prev) {
        if (values.components?.find((comp) => comp.id === id)) {
          newComponents[id] = prev[id];
        }
      }
      return { ...newComponents, ...keyedSelectedComponents };
    });
  }, [keyedSelectedComponents]);

  useEffect(() => {
    //@ts-ignore
    setValues(getCreateCustomizeFoodFormInitialValues(food));
  }, [food]);

  const handleComponentsChange = async (rows: MenuFoodComponent[]) => {
    setFieldValue('components', rows);
  };

  const selectedComponents = getOrderedComponents(values.components!, allComponents);

  const handleSumMacrosData = (allComponents: Dictionary<FoodComponent>, macrosType: Nutrition) => {
    return round(
      sumBy(selectedComponents, (fc) => {
        const component = allComponents[fc.id];
        if (!component) return 0;
        const componentWeight = ComponentService.calculateComponentWeight(
          component.cups,
          component.measurementUnit,
          component.weight ?? 1,
          fc?.quantity || 0
        );
        return (component?.macros[macrosType] || 0) * componentWeight;
      })
    );
  };

  const totalCalories = handleSumMacrosData(allComponents, 'cal' as any);
  const totalProteinWeight = handleSumMacrosData(allComponents, Nutrition.protein);
  const totalCarbsWeight = handleSumMacrosData(allComponents, Nutrition.carbs);
  const totalFatWeight = handleSumMacrosData(allComponents, Nutrition.fat);

  const createPrototypeMeal = async () => {
    const food = omit(selectedFood, [
      'id',
      'createdAt',
      'dataType',
      'updatedAt',
      'lowercaseName',
      'slug',
      'fhk',
      'numberOfRatings',
      'totalRating',
      'usedOnMenu',
      'lastUsedOnMenu',
      'periodicAggregatedRatings',
      'totalNumberOfFavorites',
      'totalNumberOfBlocks',
      'purchasingCost'
    ]) as Food;
    food.size = selectedSize.toUpperCase();
    food.brand = food.brand ? food.brand : Brand.CALO;
    food.country = food.country ? food.country : Country.BH;
    food.kitchen = food.kitchen ? food.kitchen : KitchenType.BH1;
    food.macros = { cal: totalCalories, protein: totalProteinWeight, carbs: totalCarbsWeight, fat: totalFatWeight };
    food.components = (values.components ?? []).map((comp) => {
      return {
        id: comp.id,
        quantity: comp.quantity ?? 0
      };
    });
    const response = await createPrototypeFoodMutation(food as any);
    setFoodWithAllSizes([...foodWithAllSizes, response]);
    setSelectedFood(response);
    close();
    setSelectedSize('');
  };

  return (
    <Stack>
      <Stack sx={{ mt: 1 }} justifyContent={'space-between'} direction={'row'} gap={4} width={'100%'}>
        <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>Results: Updated Meal Components</Typography>
      </Stack>
      <Box sx={{ mt: 4 }}>
        {isLoadingPrototypeFood || isLoadingComponent ? (
          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        ) : (
          <CustomizedFoodTable
            isDisabled={false}
            isChild={false}
            onChange={handleComponentsChange}
            allComponents={allComponents}
            selectedComponents={selectedComponents}
          />
        )}
      </Box>
      <Stack sx={{ mt: 1 }} justifyContent={'center'} direction={'row'} gap={4} width={'100%'}>
        <LoadingButton
          variant="outlined"
          sx={{
            padding: '10px, 16px',
            color: caloTheme.palette.neutral900,
            '&:hover': {
              color: caloTheme.palette.neutral900,
              boxShadow: 'none'
            },
            fontWeight: 600
          }}
          loading={isLoadingPrototypeFood}
          onClick={submitForm}
        >
          <Typography sx={{ color: caloTheme.palette.black, fontWeight: 600, fontSize: '18px' }}>Regenerate</Typography>
        </LoadingButton>
        <LoadingButton
          variant="outlined"
          sx={{
            padding: '10px, 16px',
            backgroundColor: caloTheme.palette.primary500,
            '&:hover': {
              backgroundColor: caloTheme.palette.primary600,
              boxShadow: 'none'
            },
            borderColor: caloTheme.palette.primary500,
            fontWeight: 600
          }}
          loading={isLoadingPrototypeFood}
          onClick={createPrototypeMeal}
        >
          <Typography sx={{ color: caloTheme.palette.white, fontWeight: 600, fontSize: '18px' }}>Confirm</Typography>
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default CustomizeFoodMacrosResultsCard;
