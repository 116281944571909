import { IngredientsFilters, PrototypeAction, UpdateIngredientReq, UpdatePrototypeActionsReq } from '@calo/dashboard-types';
import client from 'lib/client';
import { ToastSuccessMsgs, getPrototypeActionSuccessMessage } from 'lib/helpers';
import { IngredientFormData } from 'lib/interfaces';
import mutation from './mutation';

export const createIngredient = async (values: IngredientFormData) => {
  const { data, status } = await client.post('/ingredients', values);
  ToastSuccessMsgs({ status, action: 'New Ingredient created successfully' });
  mutation(['ingredient', data.id], data);
  return data;
};

export const updateIngredient = async (payload: UpdateIngredientReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/ingredients/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Ingredient updated successfully' });
  mutation(['ingredient', id], data);
  return data;
};

export const exportIngredient = async (filters: IngredientsFilters) => {
  const { status } = await client.get('/ingredients/export', { params: { filters } });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
};

export const createPrototypeIngredient = async (values: IngredientFormData) => {
  const { data, status } = await client.post('/ingredients/prototype', values);
  ToastSuccessMsgs({ status, action: 'New Ingredient created successfully' });
  mutation(['ingredient', data.id], data);
  return data;
};

export const updatePrototypeIngredient = async (payload: UpdateIngredientReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/ingredients/prototype/${id}`, rest);
  ToastSuccessMsgs({ status, action: 'Ingredient updated successfully' });
  mutation(['ingredient', id], data);
  return data;
};

export const addPrototypeIngredientAction = async ({ id, ...rest }: UpdatePrototypeActionsReq & { id: string }) => {
  const { data, status } = await client.put(`/ingredients/prototype/${id}/add-action`, rest);
  const message = getPrototypeActionSuccessMessage(rest.action, 'Ingredient');
  ToastSuccessMsgs({ status, action: message });
  return data;
};

export const approvePrototypeIngredient = async (id: string) => {
  const { data, status } = await client.put(`/ingredients/prototype/${id}/approve`, { action: PrototypeAction.approve });
  const message = getPrototypeActionSuccessMessage(PrototypeAction.approve, 'Ingredient');
  ToastSuccessMsgs({ status, action: message });
  return data;
};

export const deletePrototypeIngredient = async (id: string) => {
  const { status } = await client.delete(`/ingredients/prototype/${id}`);
  ToastSuccessMsgs({ status, action: 'Ingredient deleted successfully' });
};

// export const deleteFood = async (id: string) => {
//   await client.delete(`/food/${id}`)
// }
export const deleteIngredient = async (payload: { id: string }) => {
  const { id } = payload;
  const { data, status } = await client.delete(`/ingredients/${id}`);
  ToastSuccessMsgs({ status, action: 'Ingredient deleted successfully' });
  return data;
};
