import { Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { FoodPreference, HealthConditionNote, HealthConditions, IngredientCategory, Kitchen } from '@calo/types';
import { Box, MenuItem, Stack, TextField } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { healthConditionsIngredientLists } from 'lib/helpers/healthConditionsList';
import { startCase } from 'lodash-es';
import FoodRestrictionItem from './FoodRestrictionItem';

interface HealthConditionProps {
  healthConditionsData: HealthConditions[] | undefined;
  setHealthConditionData: React.Dispatch<React.SetStateAction<HealthConditions[]>>;
  setHealthConditionIngredient: React.Dispatch<React.SetStateAction<string[]>>;
  headerFoodRestrictionOpen: boolean;
  selectedCustomer: Subscription & { foodPreference?: FoodPreference };
  warningIngredients: IngredientCategory[];
  setWarningIngredients: React.Dispatch<React.SetStateAction<IngredientCategory[]>>;
  handleUpdateAllergicFood: (data: UpdateSubscriptionReq) => Promise<void>;
  updateAllergicIngMutation: (data: any) => Promise<void>;
  allergicIngredients: any[];
  customerNotes: HealthConditionNote[];
  favMealIds: string[];
}

const HealthCondition = ({
  healthConditionsData,
  setHealthConditionData,
  setHealthConditionIngredient,
  headerFoodRestrictionOpen,
  selectedCustomer,
  warningIngredients,
  setWarningIngredients,
  handleUpdateAllergicFood,
  updateAllergicIngMutation,
  allergicIngredients,
  customerNotes,
  favMealIds
}: HealthConditionProps) => {
  const handleUpdateFoodPrefHealthCondition = async (data: any) => {
    await updateAllergicIngMutation({
      subscriptionId: selectedCustomer.id,
      allergicIng: allergicIngredients,
      healthConditions: data,
      notes: customerNotes,
      favorite: favMealIds
    });
  };

  const handleHealthConditionsSelector = (selectedHC: HealthConditions[]) => {
    const newHealthCondition = selectedHC.find((HC) => !healthConditionsData?.includes(HC));
    // @ts-ignore
    const healthConditionIngredientNameList =
      healthConditionsIngredientLists[newHealthCondition!][selectedCustomer.kitchen || Kitchen.BH1];
    handleUpdateFoodPrefHealthCondition(selectedHC);
    setHealthConditionData(selectedHC);
    if (!warningIngredients?.includes(IngredientCategory.other)) {
      handleUpdateAllergicFood({
        ...selectedCustomer,
        macrosData: {
          ...selectedCustomer.macrosData,
          allergicFood: [...warningIngredients, IngredientCategory.other]
        }
      });
      setWarningIngredients(() => [...warningIngredients, IngredientCategory.other]);
    }
    setHealthConditionIngredient(healthConditionIngredientNameList);
  };

  return (
    <Box style={{ marginTop: '4px', display: headerFoodRestrictionOpen ? 'flex' : 'none', flexDirection: 'column' }}>
      <Stack style={{ marginTop: 12, marginBottom: 12 }}>
        <TextField
          select
          label="Health Condition"
          value={healthConditionsData || []}
          placeholder="Select Ingredients..."
          SelectProps={{
            multiple: true,
            value: healthConditionsData || [],
            onChange: (e) => handleHealthConditionsSelector(e.target.value as HealthConditions[]),
            placeholder: 'Select Ingredients...'
          }}
          sx={{ my: 1 }}
          InputProps={{ inputProps: { style: { borderRadius: 8 } }, style: { borderRadius: 8 } }}
        >
          {Object.values(HealthConditions).map((HC) => (
            <MenuItem key={HC} value={HC}>
              {startCase(HC.toLowerCase())}
            </MenuItem>
          ))}
        </TextField>
        <Box sx={{ padding: '4px', display: healthConditionsData ? 'flex' : 'none' }}>
          <Stack
            sx={{
              border: 0,
              borderColor: caloTheme.palette.neutral100,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            {healthConditionsData &&
              healthConditionsData.length > 0 &&
              healthConditionsData.map((healthCondition: HealthConditions) => (
                <FoodRestrictionItem
                  key={healthCondition}
                  keyProp={healthCondition}
                  text={startCase(healthCondition.toLowerCase())}
                  onClick={() => {
                    setHealthConditionData(healthConditionsData.filter((HC) => HC !== healthCondition));
                    handleUpdateFoodPrefHealthCondition(healthConditionsData.filter((HC) => HC !== healthCondition));
                  }}
                />
              ))}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default HealthCondition;
