import { Permission } from '@calo/dashboard-types';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Ingredient } from 'lib/interfaces';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

const IngredientListCard = ({ isLoading, ingredients }: { isLoading: boolean; ingredients: Ingredient[] | undefined }) => {
  const userRoles = useUserRoles();

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        border: 'none',
        padding: 2,
        paddingX: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '16px',
        gap: '8px'
      }}
    >
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
            <TableRow>
              <TableCell
                sx={{
                  borderRadius: '8px 0 0 8px',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  minWidth: '50%'
                }}
              >
                Ingredient Name
              </TableCell>
              <TableCell
                sx={{
                  borderRadius: '0 8px 8px 0',
                  border: 0,
                  fontWeight: 600,
                  lineHeight: '14px',
                  fontSize: '12px',
                  width: '50%'
                }}
              >
                Key
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ingredients?.map((ingredient: Ingredient) => (
              <TableRow key={ingredient.id} sx={{ borderBottom: '1px solid' + caloTheme.palette.neutral100 }}>
                <TableCell sx={{ fontSize: '14px' }}>
                  {userRoles.includes(Permission.VIEW_INGREDIENT) ? (
                    <Link to={Routes.ingredientSlug.replace(':slug', ingredient.slug)} style={{ color: '#367BF5' }}>
                      {ingredient.name.en}({ingredient.internalName && ingredient.internalName})
                    </Link>
                  ) : (
                    `${ingredient.name.en}-(${ingredient.internalName && ingredient.internalName})`
                  )}
                </TableCell>
                <TableCell sx={{ fontSize: '14px' }}>{ingredient.slug}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && isEmpty(ingredients) && <Typography sx={{ textAlign: 'center' }}>No Ingredients Found</Typography>}
    </Card>
  );
};

export default IngredientListCard;
