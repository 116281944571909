import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { Ingredient } from 'lib/interfaces';
import { round } from 'lodash-es';

import { Permission } from '@calo/dashboard-types';
import { FoodComponentIngredientAction } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton, Stack, TableCell, TextField, Typography } from '@mui/material';

import InputThemeProvider from '../InputThemeProvider';

interface IngredientRowProps {
  isDisabled: boolean;
  row: Ingredient;
  index: number;
  handleChange: (id: string, index: number, quantity: number) => void;
  handleActionChange: (id: string, index: number, action: FoodComponentIngredientAction) => void;
  handleRemoveIngredient: (index: number, removedId: string) => void;
}

const IngredientRow = ({ isDisabled, row, index, handleChange, handleRemoveIngredient }: IngredientRowProps) => {
  const roles = useUserRoles();
  const isDeleteButtonActive = !isDisabled;

  return (
    <>
      <TableCell sx={{ p: 0 }}>
        <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          {!isDisabled && (
            <IconButton
              data-test={`handleRemoveIngredient#${index}`}
              disabled={!isDeleteButtonActive}
              onClick={() => handleRemoveIngredient(index, row.id)}
            >
              <Iconify
                color={isDeleteButtonActive ? caloTheme.palette.neutral900 : caloTheme.palette.neutral600}
                width={24}
                icon="uil:trash"
              />
            </IconButton>
          )}
          <Typography sx={{ position: 'relative' }}>
            {row.name.en}-(
            {row.internalName && row.internalName})
            {row.prototype && (
              <Typography
                sx={{ position: 'absolute', right: 0, top: -15, fontSize: '12px', color: caloTheme.palette.primary500 }}
              >
                (Draft)
              </Typography>
            )}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell sx={{ width: '150px' }}>
        <InputThemeProvider>
          <TextField
            data-test={`ingredientQuantityInput#${index}`}
            type="number"
            value={row.quantity}
            defaultValue={row.quantity ?? 0}
            onChange={(e) => handleChange(row.id, index, parseFloat(e.target.value))}
            disabled={isDisabled}
            inputProps={{
              min: 0
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: caloTheme.palette.primary500
                }
              }
            }}
          />
        </InputThemeProvider>
      </TableCell>

      <TableCell sx={{ fontSize: '16px' }}>{row.measurementUnit}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{(row.quantity || 0) * (row.weight || 1)}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {row.macros?.cal && row.quantity ? round(row.macros!.cal * row.quantity, 6) : 0}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {row.macros?.protein && row.quantity ? round(row.macros!.protein * row.quantity, 6) : 0}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {row.macros?.carbs && row.quantity ? round(row.macros!.carbs * row.quantity, 6) : 0}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {row.macros?.fat && row.quantity ? round(row.macros!.fat * row.quantity, 6) : 0}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {row.macros?.fiber && row.quantity ? round(row.macros!.fiber || 0 * row.quantity, 6) : 0}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{row.cost?.toFixed(5)}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {row.cost && row.quantity && row.wastage ? round(row.cost! * row.quantity * row.wastage!, 6) : 0}
      </TableCell>
      <TableCell sx={{ p: 0 }}>
        <IconButton
          disabled={!roles.includes(Permission.VIEW_INGREDIENT)}
          onClick={() =>
            window.open(
              (row.prototype ? Routes.playgroundIngredientSlug : Routes.ingredientSlug).replace(':slug', row.slug),
              '_blank'
            )
          }
          sx={{ color: '#343434' }}
        >
          <ArrowForwardIcon color="inherit" />
        </IconButton>
      </TableCell>
    </>
  );
};

export default IngredientRow;
