import { useEffect, useRef, useState } from 'react';

import { createFoodComponent } from 'actions';
import { ModalRef } from 'components';
import { InputValueRestrictionType, Routes } from 'lib/enums';
import { isNumberInputValid } from 'lib/helpers';
import { FoodComponent, Ingredient, IngredientHeaderItem } from 'lib/interfaces';
import { isNil, sortBy } from 'lodash-es';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';

import { CreateFoodComponentReq, MeasurementUnit, Permission } from '@calo/dashboard-types';
import { Macros, Micronutrients } from '@calo/types';

import {
  ComponentHeaderCard,
  ComponentMethodCard,
  CupsListCard,
  CustomSectionCard,
  MacrosMicrosInputCard
} from 'components/FoodComponentShared';
import {
  AdditionalInformationCard,
  ChildComponentsCard,
  ComponentInformationCard,
  IngredientListCard
} from 'components/FoodComponentShared/NewComponent';
import { FormOperation } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import ComponentStandardsCard from './ComponentStandardsCard';
import useFoodComponentForm from './useFoodComponentForm';

const NewFoodComponent = () => {
  const history = useHistory();
  const roles = useUserRoles();
  const isDisabled = !roles.includes(Permission.CREATE_FOOD_COMPONENTS);
  const { mutateAsync: createMutation } = useMutation(createFoodComponent);

  const handleUpdateFood = (values: Partial<FoodComponent>, structuredIngredients: IngredientHeaderItem[]) => {
    const updatedIngredients: Ingredient[] = [];
    let tempHeaderName: string | null | undefined = null;
    for (const structuredIngredient of structuredIngredients) {
      if (structuredIngredient.type === 'header') {
        tempHeaderName = structuredIngredient.header;
      } else {
        updatedIngredients.push({ ...structuredIngredient.ingredient, header: tempHeaderName ?? undefined });
      }
    }

    const { ...rest } = values;
    return createMutation({ ...rest, ingredients: updatedIngredients } as CreateFoodComponentReq);
  };

  const handleCreateFoodComponent = async (values: CreateFoodComponentReq) => {
    const foodComponent = await handleUpdateFood(values, structuredIngredients);
    history.replace(Routes.foodComponentSlug.replace(':slug', foodComponent.slug));
  };

  const { values, errors, touched, isValid, setValues, handleBlur, handleSubmit, handleChange, isSubmitting, setFieldValue } =
    useFoodComponentForm(handleCreateFoodComponent);

  const [childComponents, setChildComponents] = useState<FoodComponent[]>([]);
  const [calculatedMacrosFromIngredients, setCalculatedMacrosFromIngredients] = useState<Macros | undefined>();
  const [calculatedMicronutrientsFromIngredients, setCalculatedMicronutrientsFromIngredients] = useState<
    Micronutrients | undefined
  >();
  const [isCalculatedMacrosDifferent, setIsCalculatedMacrosDifferent] = useState(false);
  const [structuredIngredients, setStructuredIngredients] = useState<IngredientHeaderItem[]>([]);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const calculatedMacrosRef = useRef<ModalRef>();

  let currentHeader: string | null | undefined;
  useEffect(() => {
    const sortedIngredients = sortBy(values.ingredients, [
      (ingredient) => (isNil(ingredient.header) ? -1 : 0), // Sort undefined headerName first
      'header' // Then sort by headerName alphabetically
    ]);

    const updatedStructuredIngredients: IngredientHeaderItem[] = [];
    for (const ingredient of sortedIngredients) {
      if (ingredient.header !== currentHeader && ingredient.header !== null && ingredient.header !== undefined) {
        currentHeader = ingredient.header;
        updatedStructuredIngredients.push({ type: 'header', header: currentHeader });
      }
      updatedStructuredIngredients.push({ type: 'ingredient', ingredient });
    }

    setStructuredIngredients(updatedStructuredIngredients);
  }, [values.ingredients]);

  useEffect(() => {
    setFieldValue('ingredients', []);
    setFieldValue('purchasingCost', 0);
  }, [values.country, values.brand, values.kitchen]);

  const handleChangeForNumRestrictions = (event: React.ChangeEvent<any>) => {
    const { name, value } = event.target;
    if (isNumberInputValid(value, InputValueRestrictionType.macros)) {
      setFieldValue(`${name}`, Number(value));
    }
  };

  return (
    <>
      <ComponentHeaderCard
        operation={FormOperation.create}
        isSubmitting={isSubmitting}
        isValid={isValid}
        isEdit={isEdit}
        isDisabled={isDisabled}
        route={Routes.foodComponentList}
        handleSubmit={handleSubmit}
      />
      <ComponentInformationCard
        touched={touched}
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        isSubmitting={isSubmitting}
        values={values}
      />
      <IngredientListCard
        childComponents={childComponents}
        setFieldValue={setFieldValue}
        setStructuredIngredients={setStructuredIngredients}
        setValues={setValues}
        structuredIngredients={structuredIngredients}
        values={values}
      />
      <ChildComponentsCard
        childComponents={childComponents}
        setChildComponents={setChildComponents}
        setCalculatedMacrosFromIngredients={setCalculatedMacrosFromIngredients}
        setCalculatedMicronutrientsFromIngredients={setCalculatedMicronutrientsFromIngredients}
        setFieldValue={setFieldValue}
        setIsCalculatedMacrosDifferent={setIsCalculatedMacrosDifferent}
        setValues={setValues}
        values={values}
      />
      <MacrosMicrosInputCard
        calculatedMicronutrientsFromIngredients={calculatedMicronutrientsFromIngredients}
        calculatedMacrosFromIngredients={calculatedMacrosFromIngredients}
        isCalculatedMacrosDifferent={isCalculatedMacrosDifferent}
        calculatedMacrosRef={calculatedMacrosRef}
        values={values}
        errors={errors}
        operation={FormOperation.create}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        setValues={setValues}
        handleChangeForNumRestrictions={handleChangeForNumRestrictions}
      />
      <AdditionalInformationCard
        errors={errors}
        values={values}
        childComponents={childComponents}
        handleBlur={handleBlur}
        handleChange={handleChange}
        setFieldValue={setFieldValue}
        handleChangeForNumRestrictions={handleChangeForNumRestrictions}
      />
      <CustomSectionCard values={values} setFieldValue={setFieldValue} errors={errors} />
      {values.measurementUnit === MeasurementUnit.cup && (
        <CupsListCard
          setFieldValue={setFieldValue}
          setValues={setValues}
          values={values}
          operation={FormOperation.create}
          isDisabled={isDisabled}
        />
      )}
      <ComponentMethodCard
        operation={FormOperation.create}
        errors={errors}
        values={values}
        setFieldValue={setFieldValue}
        setValues={setValues}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
      <ComponentStandardsCard values={values} setValues={setValues} />
    </>
  );
};

export default NewFoodComponent;
