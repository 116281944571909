import { LinkedComponent, MeasurementUnit, MenuFoodComponent, UpdateFoodReq } from '@calo/dashboard-types';
import { ComponentService } from '@calo/services';
import { Currency, Dictionary, DietType, Food as ExtendedFood, FoodType, Kitchen } from '@calo/types';
import { KitchenWeighted, handleMealSellingPrice, handlePurchasingCost, makeFoodSlug, setComponentsOnFood } from 'lib/index';
import { BaseOmit, FoodComponent, FoodComponentWithQuantity, Ingredient, UpdateFoodReqWithIndex } from 'lib/interfaces';
import { capitalize, round, sum, sumBy } from 'lodash';

export enum MeasurementUnitDisplay {
  g = 'gram',
  ml = 'ml',
  piece = 'piece'
}

export enum CostInfo {
  cost = 'cost',
  purchasingCost = 'purchasingCost',
  none = 'none'
}

export enum MealCostInfo {
  COMPONENT = 'component',
  PRECORO = 'precoro',
  RECIPE = 'recipe',
  PERCENTAGE = 'percentage',
  SELLINGPRICE = 'sellingPrice',
  NONE = 'none'
}

export const cookedRawFactorInfoText = (
  ingredientsTotalWeight: number,
  childComponentsTotalWeight: number,
  cookedWeight: number | undefined
) => {
  const { info } = cookedRawFactorInfoHandler(ingredientsTotalWeight, childComponentsTotalWeight, cookedWeight);
  return info;
};

export const cookedRawFactorInfoHandler = (
  ingredientsTotalWeight: number,
  childComponentsTotalWeight: number,
  cookedWeight: number | undefined
) => {
  let info = '';

  const totalWeight = ingredientsTotalWeight + childComponentsTotalWeight;
  const cookedRawFactor = round(totalWeight / (cookedWeight || 0), 3);

  const totalWeightInfo = `The total weight of the ingredients and child components is ${totalWeight} grams.`;
  const cookedWeightInfo = `The cooked weight of the component is ${cookedWeight} grams.`;
  const cookedRawFactorInfo = `The cooked raw factor is ${totalWeight} / ${cookedWeight} = ${cookedRawFactor}`;

  info = `${totalWeightInfo}, ${cookedWeightInfo}, ${cookedRawFactorInfo}`;
  return { info, cookedRawFactor };
};

export const showCostInfoHandler = (
  costToShow: CostInfo,
  ingredients: Ingredient[],
  childCompsWithQuantity: FoodComponentWithQuantity[],
  cookedRawFactor = 0,
  componentWeight: number,
  measurementUnit?: MeasurementUnit
) => {
  const { info } = purchasingCostInfo(ingredients, childCompsWithQuantity, cookedRawFactor, componentWeight);
  const header = `This is the cost per unit (${measurementUnit}) of this component calculated after catering the wastage factors and cooked weight of this component.,`;
  switch (costToShow) {
    case CostInfo.purchasingCost:
      return `${header}, ${info}`;
    case CostInfo.cost:
      return `This is the cost per unit (${measurementUnit}) for the component which is input manually from the Recipe Sheet.,`;
    default:
      return '';
  }
};

export const purchasingCostInfo = (
  ingredients: Ingredient[],
  childCompsWithQuantity: FoodComponentWithQuantity[],
  cookedRawFactor: number,
  componentWeight: number
) => {
  let info = '';
  let purchasingCost = 0;
  let totalPurchasingCost = 0;
  if (ingredients.length > 0) {
    const ingPurchasingCosts = ingredients.map((ing) => handlePurchasingCost(ing));
    const ingQuantities = ingredients.map((ing) => {
      return (ing.quantity || 0) * (ing.weight || 1);
    });

    let purchasingCostSum = sum(ingPurchasingCosts);
    let quantitiesSum = sum(ingQuantities);

    for (const child of childCompsWithQuantity) {
      if (!child.ingredients) continue;
      const childIngredientsWithPurchasingCost = child.ingredients.map((ingredient) => ({
        ...ingredient,
        purchasingCost: handlePurchasingCost(ingredient)
      }));

      const childPurchasingCost = sumBy(childIngredientsWithPurchasingCost, 'purchasingCost');
      const childQuantitesTotal = sumBy(childIngredientsWithPurchasingCost, 'quantity');

      const childWeight = ComponentService.calculateComponentWeight(
        child.cups,
        child.measurementUnit,
        child.weight ?? 1,
        child?.quantity ?? 0
      );

      const totalCostPerGram = (childPurchasingCost / childQuantitesTotal) * (child.cookedRawFactor ?? 1) * child.quantity;

      purchasingCostSum += totalCostPerGram;
      ingPurchasingCosts.push(totalCostPerGram);

      quantitiesSum += childWeight;
      ingQuantities.push(childWeight);
    }
    const ingredientCostsInfo = `Total Ingredients Cost = ${ingPurchasingCosts.map((cost) => round(cost, 6)).join(' + ')} = ${round(purchasingCostSum, 6)}`;

    purchasingCost = purchasingCostSum / quantitiesSum;
    const ingQuantitiesInfo = `Total Ingredient Quantities = ${ingQuantities.join(' + ')} = ${round(quantitiesSum, 6)}`;

    const totalPurchasingCostInfo = `Purchasing Cost = ${round(purchasingCostSum, 6)} / ${round(quantitiesSum, 6)} = ${round(purchasingCost, 6)}`;
    const purchasingCostPerGram = `Purchasing Cost Per Gram = ${round(purchasingCost, 6)} * ${cookedRawFactor}(cooked raw factor) = ${round(purchasingCost * cookedRawFactor, 6)}`;
    const finalPurchasingCost = `Final Purchasing Cost = ${round(purchasingCost * cookedRawFactor, 6)} * ${componentWeight} (cooked weight in grams) = ${round(purchasingCost * cookedRawFactor * componentWeight, 6)}`;

    totalPurchasingCost = round(purchasingCost * cookedRawFactor, 6);

    info = `${ingredientCostsInfo},${ingQuantitiesInfo},${totalPurchasingCostInfo},${purchasingCostPerGram},${finalPurchasingCost}`;
  }
  return { info, totalPurchasingCost };
};

export const showMealCostInfoHandler = (
  costToShow: MealCostInfo,
  values: UpdateFoodReqWithIndex,
  allComponents: Dictionary<FoodComponent>,
  childComponent: Dictionary<FoodComponent>,
  packagingCost: number
) => {
  if (!values.components) return '';
  switch (costToShow) {
    case MealCostInfo.COMPONENT:
      return CalculateAndDisplayFoodCosts(MealCostInfo.COMPONENT, values, allComponents, childComponent, packagingCost);
    case MealCostInfo.PRECORO:
      return CalculateAndDisplayFoodCosts(MealCostInfo.PRECORO, values, allComponents, childComponent, packagingCost);
    case MealCostInfo.RECIPE:
      return CalculateAndDisplayFoodCosts(MealCostInfo.RECIPE, values, allComponents, childComponent, packagingCost);
    case MealCostInfo.SELLINGPRICE:
      return getMealSellingPricePerTag(values);
    default:
      return '';
  }
};

export const getComponentsTotalCost = (
  values: UpdateFoodReqWithIndex,
  allComponents: Dictionary<FoodComponent>,
  childComponent: Dictionary<FoodComponent>
) => {
  if (!allComponents) return 0;
  const foodWithComponents = populateMealWithComponentData(values, allComponents, childComponent);
  if (!foodWithComponents || foodWithComponents.length === 0) {
    return 0;
  }
  const { totalPurchasingCost } = componentPurchasingCostInfo(foodWithComponents[0].components);
  return totalPurchasingCost;
};

export const getMealTotalCost = (
  values: UpdateFoodReqWithIndex,
  allComponents: Dictionary<FoodComponent>,
  childComponent: Dictionary<FoodComponent>,
  packagingCost: number
) => {
  if (!allComponents) return 0;
  const foodWithComponents = populateMealWithComponentData(values, allComponents, childComponent);
  if (!foodWithComponents || foodWithComponents.length === 0) {
    return 0;
  }
  const { totalMealCost } = mealPurchasingCostInfo(foodWithComponents[0]?.components, packagingCost);
  return totalMealCost;
};

export const CalculateAndDisplayFoodCosts = (
  costToShow: MealCostInfo,
  values: UpdateFoodReqWithIndex,
  allComponents: Dictionary<FoodComponent>,
  childComponent: Dictionary<FoodComponent>,
  packagingCost: number
) => {
  const foodWithComponents = populateMealWithComponentData(values, allComponents, childComponent);
  if (!foodWithComponents) return '';
  switch (costToShow) {
    case MealCostInfo.COMPONENT:
      return componentPurchasingCostInfo(foodWithComponents[0].components ?? []);
    case MealCostInfo.PRECORO:
      return mealPurchasingCostInfo(foodWithComponents[0].components ?? [], packagingCost);
    case MealCostInfo.RECIPE:
      return mealTotalCostInfo(foodWithComponents[0]?.components ?? [], packagingCost);
    default:
      return '';
  }
};

export const mealPurchasingCostInfo = (components: any[], packagingCost: number) => {
  let info = 'This is the total cost of components and packaging for this meal from Precoro.,';
  if (!components) return { info, totalMealCost: 0 };

  let totalComponentsPurchasingCostInfo = '';
  let totalPurchasingCost = 0;

  for (const [i, component] of components.entries()) {
    totalComponentsPurchasingCostInfo =
      i === 0
        ? 'Total Components Cost = ' + `(${round(component.purchasingCost, 6)} * ${component.quantity})`
        : totalComponentsPurchasingCostInfo + ` + (${round(component.purchasingCost, 6)} * ${component.quantity})`;

    totalPurchasingCost += component.purchasingCost * component.quantity;
  }
  totalComponentsPurchasingCostInfo += ` =  ${round(totalPurchasingCost, 6)}`;

  const packagingInfo = packagingCost ? `Total Packaging Costs = ${packagingCost}` : `Total Packaging Costs = 0`;

  const totalMealCost = totalPurchasingCost + packagingCost;
  const totalMealCostInfo = `Total Meal Cost = ${round(totalPurchasingCost, 6)} + ${round(packagingCost, 6)} = ${round(totalMealCost, 6)}`;

  info = `${info}, ${totalComponentsPurchasingCostInfo} , ${packagingInfo} , ${totalMealCostInfo}`;

  return { info, totalMealCost };
};

export const mealTotalCostInfo = (components: any[], packagingCost: number) => {
  let info = '';
  if (!components) return info;
  const componentCost = components?.map((r) => (r?.cost || 0) * r.quantity);
  const cC = round(
    sumBy(componentCost, (fk) => fk || 0),
    3
  );
  info = `This cost is manually entered and calculated from the Recipe Sheet. It includes the packaging cost., ${cC} + ${packagingCost} = ${packagingCost + cC}`;
  return info;
};
export const componentPurchasingCostInfo = (components: any[]) => {
  let info = 'This is the total cost of all components in this meal coming directly from Precoro,';
  let totalPurchasingCost = 0;
  if (!components) return { info, totalPurchasingCost };
  for (const component of components) {
    info += `${component?.name?.en}: ,`;
    const replicatedChildComponents = component.childComponents || [];
    const result = purchasingCostInfo(
      component.ingredients || [],
      replicatedChildComponents || [],
      component.cookedRawFactor,
      component.weight ?? 1
    );
    info += `${result.info} ,`;
    info += `Total Component Cost = 
      ${round((component.weight ?? 1) * result.totalPurchasingCost, 6)} 
      *
      ${component.quantity}
      (quantity)
      =  
      ${round(result.totalPurchasingCost * component.quantity * component.weight, 6)}
      , 
      -,`;
    totalPurchasingCost += result.totalPurchasingCost * (component.weight || 1) * component.quantity;
  }

  info += `Total Components Cost = ${round(+totalPurchasingCost, 6)}`;
  return { info, totalPurchasingCost };
};

const populateMealWithComponentData = (
  values: UpdateFoodReqWithIndex,
  allComponents: Dictionary<FoodComponent>,
  childComponent: Dictionary<FoodComponent>
) => {
  if (!values.components) return;
  const slug = makeFoodSlug(values.name!, values.brand, values.kitchen!);

  if (!values || values.components.length === 0 || !slug) {
    return;
  }

  const foodWithComponents = setComponentsOnFood([values] as ExtendedFood[], Object.values(allComponents));

  // on each component in foodWithComponents, set the childComponents property to the childComponents of the component in allComponents using childComponent map
  for (const [index, _] of foodWithComponents[0].components.entries()) {
    if (foodWithComponents[0].components[index].childComponents) {
      foodWithComponents[0].components[index].childComponents = foodWithComponents[0].components[index].childComponents?.map(
        (ch) => ({ ...childComponent[ch.id], quantity: ch.quantity })
      );
    }
  }

  return foodWithComponents;
};

export const getChildCompsWithQuantity = (
  childComponents: Dictionary<FoodComponent>,
  allComponents: Dictionary<FoodComponent>,
  component: LinkedComponent
) => {
  return Object.values(childComponents).map((child) => ({
    ...child,
    quantity: allComponents[component.id].childComponents?.find((comp) => comp.id === child.id)?.quantity ?? 0
  }));
};

export const getMealSellingPricePerTag = (meal: UpdateFoodReqWithIndex) => {
  let info = 'This is the price at which we sell this meal.,';
  const prices: { tag: string; price: number }[] = [];

  const addPriceInfo = (tag: string, price: number) => {
    prices.push({ tag: capitalize(tag), price });
    info += `${capitalize(tag)}: ${price} ${meal.currency || Currency.BHD}, `;
  };

  if (meal.size && ['S', 'M', 'L'].includes(meal.size)) {
    for (const tag of meal.tags ?? []) {
      const sellingPrice = meal.type?.[0]
        ? handleMealSellingPrice(meal.kitchen ?? Kitchen.BH1, meal.type || [], [tag], meal.type[0])
        : 0;
      addPriceInfo(tag, sellingPrice);
    }
  } else if (meal.size) {
    addPriceInfo(meal.size, meal.price ?? 0);
  }

  if (prices.length === 0) return info;

  if (prices.length > 1) {
    const weightLossPrice =
      (meal.tags ?? []).includes(DietType.weightLoss) && (meal.type ?? []).includes(FoodType.snack)
        ? prices.find((price) => price.tag.toLowerCase() === DietType.weightLoss)
        : null;

    if (weightLossPrice) {
      info += `Weight Loss is preferred`;
    } else {
      const balancedPrice = prices.find((price) => price.tag.toLowerCase() === DietType.balanced);
      if (balancedPrice) {
        info += `Balanced is preferred`;
      } else {
        const lowestPrice = prices.reduce((min, current) => (current.price < min.price ? current : min), prices[0]);
        const highestPrice = prices.reduce((max, current) => (current.price > max.price ? current : max), prices[0]);
        info += `${lowestPrice.tag} is to be preferred over ${highestPrice.tag}`;
      }
    }
  }
  return info;
};

export const calculateWAC = (
  modifiedFoodSizes: any[],
  handleMealSellingPrice: (kitchen: Kitchen, type: any, tags: string[], foodType: FoodType) => number,
  values: Omit<UpdateFoodReq, BaseOmit>,
  allComponents: Dictionary<FoodComponent>,
  childComponent: Dictionary<FoodComponent>,
  setMealPercentages: React.Dispatch<
    React.SetStateAction<{
      value: string;
      text: string;
    }>
  >,
  getWeightedAverage?: (kitchen: Kitchen | KitchenWeighted, size: string) => number
): string => {
  if (!values.components) return '';
  const mealName = modifiedFoodSizes[0]?.name?.en || 'Unknown Meal';
  let result = `
  This cost is a percentage of how much of our food cost accounts for the selling price.,-,
  Each size cost is multiplied by their respective weights and then added to find out WAC.
  After finding the WAC we divide it by the SP (Selling Price) of the meal.
  In case we don't have 3 sizes we will just average the sizes available.,-,
  Meal: ${mealName},`;

  let WAC = 0;
  let weightCostPerSize = '';
  let detailedWAC = 'WAC = (';
  const useDetailed = typeof getWeightedAverage === 'function';

  for (const food of modifiedFoodSizes) {
    const componentCost = round(getComponentsTotalCost(food, allComponents, childComponent), 6);
    if (useDetailed) {
      const weight = getWeightedAverage!(food.kitchen || Kitchen.BH1, food.size);
      weightCostPerSize += `Size ${food.size}: ${(weight * 100).toFixed(2)}%, `;
      WAC += componentCost * weight;
      detailedWAC += `(${componentCost.toFixed(3)} * ${(weight * 100).toFixed(2)}%) + `;
    } else {
      weightCostPerSize += ``;
      WAC += componentCost;
      detailedWAC += `(${componentCost.toFixed(3)}) + `;
    }
    result += `Size ${food.size} costs ${componentCost.toFixed(3)}, `;
  }

  detailedWAC = detailedWAC.slice(0, -3);
  detailedWAC += useDetailed
    ? `) = ${WAC.toFixed(3)}`
    : `) / ${modifiedFoodSizes.length} = ${(WAC / modifiedFoodSizes.length).toFixed(3)}`;
  result += `Weighted Average: , ${weightCostPerSize} ${detailedWAC},`;

  const sellingPrice = values.type?.[0]
    ? round(handleMealSellingPrice(values.kitchen ?? Kitchen.BH1, values.type, values.tags ?? [], values.type[0]), 6)
    : 0;
  result += `WAC divided by SP (Selling Price) x 100 = FOOD COST %,`;
  const percentageCost =
    sellingPrice === 0
      ? 0
      : useDetailed
        ? ((WAC * 100) / sellingPrice).toFixed(3)
        : (((WAC / modifiedFoodSizes.length) * 100) / sellingPrice).toFixed(3);
  result += `= (${useDetailed ? WAC.toFixed(3) : (WAC / modifiedFoodSizes.length).toFixed(3)} * 100) / ${sellingPrice.toFixed(3)} = ${percentageCost}%,`;

  const footer = useDetailed
    ? `* Weights are based on the past 3 months of deliveries according to the meal portion sizes delivered.`
    : '';
  result += ` ${footer}`;

  setMealPercentages({ value: `${percentageCost}%`, text: result });
  return result;
};

export const getFoodComponentAmount = (component: MenuFoodComponent) => {
  return component
    ? ComponentService.calculateComponentWeight(
        component.cups!,
        component.measurementUnit!,
        component.weight ?? 0,
        component.quantity,
        6
      )
    : 0;
};

export const findAutoCalculatedMacros = (menuComponents: MenuFoodComponent[]) => {
  return {
    cal: Math.round(sumBy(menuComponents, (fc) => (fc.macros?.cal ?? 0) * getFoodComponentAmount(fc))),
    protein: Math.round(sumBy(menuComponents, (fc) => (fc.macros?.protein ?? 0) * getFoodComponentAmount(fc))),
    carbs: Math.round(sumBy(menuComponents, (fc) => (fc.macros?.carbs ?? 0) * getFoodComponentAmount(fc))),
    fat: Math.round(sumBy(menuComponents, (fc) => (fc.macros?.fat ?? 0) * getFoodComponentAmount(fc))),
    fiber: Math.round(sumBy(menuComponents, (fc) => (fc.macros?.fiber || 0) * getFoodComponentAmount(fc)) || 0)
  };
};

export const findAutoCalculatedMicros = (menuComponents: MenuFoodComponent[]) => {
  return {
    addedSugar: round(
      sumBy(menuComponents, (fc) => (fc.micronutrients?.addedSugar || 0) * getFoodComponentAmount(fc)),
      4
    ),
    cholesterol: round(
      sumBy(menuComponents, (fc) => (fc.micronutrients?.cholesterol || 0) * getFoodComponentAmount(fc)),
      4
    ),
    saturatedFats: round(
      sumBy(menuComponents, (fc) => (fc.micronutrients?.saturatedFats || 0) * getFoodComponentAmount(fc)),
      4
    ),
    sodium: round(
      sumBy(menuComponents, (fc) => (fc.micronutrients?.sodium || 0) * getFoodComponentAmount(fc)),
      4
    ),
    totalSugar: round(
      sumBy(menuComponents, (fc) => (fc.micronutrients?.totalSugar || 0) * getFoodComponentAmount(fc)),
      4
    ),
    transFats: round(
      sumBy(menuComponents, (fc) => (fc.micronutrients?.transFats || 0) * getFoodComponentAmount(fc)),
      4
    )
  };
};
