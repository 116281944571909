import { Macros } from '@calo/types';
import client from 'lib/client';

export const getPrototypeFoodCustomMacros = async (id: string, macros: Macros) => {
  try {
    const { data } = await client.get(
      `/food/prototype/${id}/calculate-custom-macros?cal=${macros.cal}&protein=${macros.protein}&carbs=${macros.carbs}&fat=${macros.fat}`
    );
    return data.food;
  } catch (error) {
    console.error('Error in getPrototypeFoodCustomMacros:', error);
    throw error;
  }
};
