import { useEffect, useMemo, useState } from 'react';

import { sortBy } from 'lodash-es';

import { AddonsMenuFoodItem } from '@calo/dashboard-types';
import { FoodType } from '@calo/types';

import { handleSearch } from 'lib/helpers';
import { MenuFood } from 'lib/interfaces';
import Button from '../Button';
import Modal from '../Modal';
import Select from '../Select';
import { toast } from 'react-toastify';

interface AddonsPickerProps {
  addonsRef: any;
  addonsList: MenuFood[];
  addonsItem: AddonsMenuFoodItem;
  existAddons: AddonsMenuFoodItem[];
  setFilterName?: (value: string) => void;
  handleSelectedAddons?: (id: string) => void;
  AddToTable: (value: AddonsMenuFoodItem) => void;
  isLoading: boolean;
}

const AddonsPicker = ({
  AddToTable,
  addonsList,
  addonsRef,
  addonsItem,
  existAddons,
  setFilterName,
  handleSelectedAddons,
  isLoading
}: AddonsPickerProps) => {
  const [FoodTypes, setFoodTypes] = useState<FoodType>(FoodType.breakfast);
  const [addonsItem1, setAddonsItem1] = useState<AddonsMenuFoodItem | undefined>(addonsItem);

  const checkExistence = (id: string) => existAddons.find((existAddon) => existAddon.id === id);
  const type = addonsList.filter((r) => r.type.includes(FoodTypes) && !checkExistence(r.id));

  const options = useMemo(
    () =>
      sortBy(type, (f) => `${f.name.en}-${f.size}`).map((food) => ({
        name: food.name.en,
        value: food.id,
        type: food.type,
        label: `${food.name.en} (${food.size})`,
        price: food.price
      })),
    [addonsList, FoodTypes, existAddons]
  );

  const handleDataChange = () => {
    if (addonsItem1 && addonsItem1.priority && addonsItem1.id) {
      addonsRef.current?.close();
      AddToTable(addonsItem1);
    }
  };

  useEffect(() => {
    setAddonsItem1(addonsItem);
  }, [addonsItem]);

  useEffect(() => {
    setAddonsItem1(undefined);
  }, [FoodTypes]);

  return (
    <div>
      <Modal ref={addonsRef}>
        <div className="flex flex-col">
          <Select
            label="Food type"
            value={FoodTypes}
            name="FoodTypes"
            onChange={(data: any) => setFoodTypes(data.value)}
            options={Object.values(FoodType).map((t) => ({
              value: FoodType[t],
              label: t
            }))}
          />
          <Select
            label="Addons"
            onChange={(data: any) => {
              data.price === 0 && toast(`Addon has price 0!`, { type: 'warning', autoClose: 3000 });
              setAddonsItem1({ id: data.value, priority: +existAddons.length + 1 });
              handleSelectedAddons && handleSelectedAddons(data.value);
            }}
            onInputChange={(data: string, action: any) =>
              setFilterName && handleSearch({ text: data, action, name: setFilterName })
            }
            placeholder={'Search...'}
            data-test="addon-picker-addon-select"
            options={options}
            value={addonsItem1?.id}
            isLoading={isLoading}
          />
          <Button
            warning
            content="Confirm"
            disabled={!addonsItem1?.id}
            onClick={() => handleDataChange()}
            data-test="addon-picker-confirm-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default AddonsPicker;
