import { Subscription } from '@calo/dashboard-types';
import { FoodPreference, Ingredient } from '@calo/types';
import { Autocomplete, Box, CircularProgress, Stack, TextField } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Options } from 'lib/interfaces';
import { compact, startCase, uniq } from 'lodash-es';
import React from 'react';
import FoodRestrictionItem from './FoodRestrictionItem';

interface OtherDislikedIngredientsProps {
  headerFoodRestrictionOpen: boolean;
  dislikedIngredients: Ingredient[];
  setDislikedIngredients: React.Dispatch<React.SetStateAction<Ingredient[]>>;
  options: Options<Ingredient>[];
  LoadingWarningIng: boolean;
  setFilterName: React.Dispatch<React.SetStateAction<string | undefined>>;
  updateAllergicIngMutation: (data: any) => void;
  refetch: any;
  selectedCustomer: Subscription & { foodPreference?: FoodPreference };
}

const OtherDislikedIngredients = ({
  headerFoodRestrictionOpen,
  dislikedIngredients,
  setDislikedIngredients,
  options,
  LoadingWarningIng,
  setFilterName,
  selectedCustomer,
  updateAllergicIngMutation,
  refetch
}: OtherDislikedIngredientsProps) => {
  const handleChangeDislikedIng = async (data: Ingredient[]) => {
    setDislikedIngredients(data);
    await updateAllergicIngMutation({
      subscriptionId: selectedCustomer.id,
      dislikedIng: data
    });
    refetch();
  };

  return (
    <Box style={{ marginTop: '4px', display: headerFoodRestrictionOpen ? 'flex' : 'none', flexDirection: 'column' }}>
      <Stack sx={{ my: 1, pt: 1 }}>
        <Autocomplete
          style={{ borderRadius: 4 }}
          multiple
          renderTags={(value) =>
            value.map((option, index) => (
              <div key={index} style={{ display: 'none' }}>
                {option.label}
              </div>
            ))
          }
          onChange={(_, newValue, r) => {
            if (r === 'selectOption') {
              handleChangeDislikedIng(uniq(compact([...dislikedIngredients, ...newValue.map((ingredient) => ingredient.data)])));
            }
          }}
          defaultValue={[]}
          options={options}
          loading={LoadingWarningIng}
          clearIcon={null}
          onInputChange={(d, text) => {
            setFilterName(text);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Other Disliked Ingredients"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {LoadingWarningIng ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
        />

        <Box sx={{ padding: '4px' }}>
          <Stack
            sx={{
              border: 0,
              borderColor: caloTheme.palette.neutral100,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            {dislikedIngredients &&
              dislikedIngredients.length > 0 &&
              dislikedIngredients.map((dislikedIng: any) =>
                FoodRestrictionItem({
                  keyProp: `${dislikedIng.id}-${dislikedIng.internalName}-${dislikedIng.slug}`,
                  text: `${startCase(dislikedIng.name.en.toLowerCase())} (${startCase(dislikedIng.internalName)})`,
                  onClick: () => (
                    handleChangeDislikedIng(dislikedIngredients.filter((al) => al.id !== dislikedIng.id)),
                    setDislikedIngredients(() => dislikedIngredients.filter((al) => al.id !== dislikedIng.id))
                  )
                })
              )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default OtherDislikedIngredients;
