import { useRef, useState } from 'react';

import { isEmpty, uniqBy } from 'lodash-es';
import { QueryFunction, useMutation, useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Brand, Country, RetailBranch } from '@calo/types';

import { RetailMenuFoodItem, UpdateRetailMenuReq } from '@calo/dashboard-types';
import { Box, IconButton, Stack, Typography } from '@mui/material';
import { createRetailMenu, getListWithParams, postRequest, updateRetailMenu } from 'actions';
import { Button, Icon, ModalRef, RetailMenuPicker, RetailMenuTable } from 'components';
import { Routes } from 'lib/enums';
import history from 'lib/history';
import { Food } from 'lib/interfaces';
import { getCountryFromBranch, getKitchenFromBranch } from '../retailHelpers';
import useRetailMenuForm from './useRetailMenuForm';

const getListWithParamsRetail: QueryFunction<[string, Record<string, any>]> = async (e) => {
  try {
    return await getListWithParams(e);
  } catch (error) {
    console.log(error);
    return ['', {}]; // Return a default value of the expected type
  }
};

const ExactRetailMenu = () => {
  const { brand, branch } = useParams<{ country: Country; brand: Brand; branch: RetailBranch }>();
  const [filterName, setFilterName] = useState<string>();
  const [selectedRetailFoodList, setSelectedRetailFoodList] = useState<Food[]>([]);
  const { data } = useQuery<any, Error, any>(
    ['retail-menu', { brand: brand || Brand.CALO, branch: branch }],
    getListWithParamsRetail,
    {
      suspense: true
    }
  );

  const isRetailMenuNotFound = isEmpty(data);
  const retailMenu = isEmpty(data)
    ? {
        country: getCountryFromBranch(branch),
        branch,
        brand,
        food: []
      }
    : (data as any);

  const { data: retailMenuList, isLoading } = useQuery<any, Error, { data: Food[] }>(
    [
      'food/list',
      {
        filters: {
          name: filterName ? filterName : undefined,
          country: getCountryFromBranch(branch),
          brand,
          isDeleted: false,
          kitchen: getKitchenFromBranch(branch)
        },
        sort: {
          orderBy: 'name',
          orderMode: 'asc'
        },
        page: 0,
        limit: 20
      }
    ],
    postRequest,
    {
      suspense: false,
      enabled: !!filterName
    }
  );

  const { mutateAsync: updateMutation } = useMutation(updateRetailMenu);
  const { mutateAsync: createMutation } = useMutation(createRetailMenu);

  const onSubmit = async (values: UpdateRetailMenuReq) => {
    try {
      if (isRetailMenuNotFound) {
        await createMutation(
          {
            food: values.food,
            country: retailMenu.country,
            brand: retailMenu.brand,
            branch: retailMenu.branch
          },
          {
            onSuccess: () => {
              const path = generatePath(Routes.retailMenu, {
                brand: retailMenu.brand,
                branch: retailMenu.branch
              });
              history.push(path);
            }
          }
        );
      } else {
        await updateMutation(
          {
            food: values.food,
            country: retailMenu.country,
            brand: retailMenu.brand,
            branch: retailMenu.branch,
            menuId: values.menuId
          },
          {
            onSuccess: () => {
              const path = generatePath(Routes.retailMenu, {
                brand: retailMenu.brand,
                branch: retailMenu.branch
              });
              history.push(path);
            }
          }
        );
      }
    } catch {
      toast('error', { type: 'error', autoClose: 2000 });
    }
  };

  const { values, isSubmitting, isValid, dirty, handleSubmit, setValues } = useRetailMenuForm(
    retailMenu,
    brand,
    branch,
    onSubmit
  );

  const retailRef = useRef<ModalRef>();

  const [retailItem, setRetailItem] = useState<RetailMenuFoodItem>({
    id: undefined!,
    priority: undefined!,
    startDate: undefined!,
    endDate: undefined!,
    retailPrice: 0,
    retailTagList: []
  });

  const AddToTable = (value: RetailMenuFoodItem) => {
    let restOfFood = values.food.filter((item) => item.id !== value.id);
    // convert resetOffood foodModifiers Component options to array of ids only
    restOfFood = restOfFood?.map((foodItem) => ({
      ...foodItem,
      foodModifiers: foodItem?.foodModifiers?.map((modifier) => ({
        ...modifier,
        componentOptions: modifier?.componentOptions?.map((option: any) => ({ id: option.id, quantity: option.quantity }))
      }))
    }));

    setValues({
      food: [
        ...restOfFood,
        {
          id: value.id,
          priority: value.priority,
          startDate: value.startDate,
          endDate: value.endDate,
          retailPrice: value.retailPrice,
          retailTagList: value.retailTagList,
          foodModifiers: value.foodModifiers,
          disabled: value.disabled,
          swapAvailable: value.swapAvailable
        }
      ],
      branch,
      brand,
      menuId: retailMenu.id
    });
    retailRef.current?.close();
    setRetailItem({
      id: undefined!,
      priority: undefined!,
      startDate: undefined!,
      endDate: undefined!,
      retailPrice: 0,
      foodModifiers: [],
      disabled: false,
      swapAvailable: false
    });
  };

  const editRow = (value: RetailMenuFoodItem) => {
    setRetailItem({
      id: value.id,
      priority: value.priority,
      startDate: value.startDate,
      endDate: value.endDate,
      retailPrice: value.retailPrice,
      retailTagList: value.retailTagList,
      foodModifiers: value.foodModifiers,
      disabled: value.disabled
    });
    retailRef.current?.open();
  };

  const handleSelectedRetailItem = (id: string) => {
    const newRetailItem = retailMenuList?.data.find((e) => e.id === id);
    if (newRetailItem) {
      setSelectedRetailFoodList((old) => [...old, newRetailItem]);
    }
  };

  return (
    <>
      <Box className="section is-title-bar">
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center">
            <Typography variant="h6">
              <Link to={Routes.retailMenu}>Retail Menu</Link>
            </Typography>
            <Typography variant="h6"> / Edit - {retailMenu.branch}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            {retailMenu.brand && retailMenu.brand === Brand.MEALO ? (
              <Icon name="mealo" size={28} className="w-24 -mb-24" />
            ) : (
              <Icon name="calo" size={8} className="w-32 -mb-4" />
            )}
          </Stack>
        </Stack>
      </Box>
      <Box className="mx-4">
        <Box sx={{ backgroundColor: 'black', padding: 2 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ color: 'white' }}>
              <span className="icon">
                <i className="mdi mdi-account-multiple"></i>
              </span>
              Info
            </Typography>
            <Typography variant="h6" sx={{ color: 'white' }}>
              {retailMenu.kitchen}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ padding: 2 }}>
          <form onSubmit={handleSubmit}>
            <input type="hidden" name="menuId" value={retailMenu.id} />
            <RetailMenuPicker
              isLoading={isLoading}
              retailRef={retailRef}
              AddToTable={AddToTable}
              retailItem={retailItem}
              existRetail={values.food}
              setFilterName={setFilterName}
              onClose={() =>
                setRetailItem({
                  id: undefined!,
                  priority: undefined!,
                  startDate: undefined!,
                  endDate: undefined!,
                  retailPrice: 0
                })
              }
              retailList={(retailMenuList?.data || []).filter((f) => !f.deletedAt)}
              handleSelectedRetailItem={handleSelectedRetailItem}
            />
            <Button
              primary
              type="submit"
              content="Save"
              loading={isSubmitting}
              onClick={() => handleSubmit()}
              disabled={!dirty || !isValid || isSubmitting}
              data-test="retail-menu-exact-save-button"
            />
          </form>
          <Box className="my-2">
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: -2 }}>
              <IconButton onClick={() => retailRef.current?.open()} data-test="retail-menu-exact-plus-button">
                <i className="fas fa-plus"></i>
              </IconButton>
            </Box>
            <RetailMenuTable
              values={values}
              setValues={setValues}
              retailValues={values.food}
              editRow={(k) => {
                editRow(k);
              }}
              retailMenu={uniqBy(
                [...(retailMenuList?.data || []).filter((f) => !f.deletedAt), ...retailMenu.food, ...selectedRetailFoodList],
                'id'
              ).map((selectedFood) => {
                const food = values.food.find((r) => r.id === selectedFood.id);
                return {
                  ...selectedFood,
                  retail: {
                    startDate: food?.startDate,
                    endDate: food?.endDate,
                    ...selectedFood.retail,
                    disabled: food?.disabled || false,
                    swapAvailable: food?.swapAvailable || false
                  }
                };
              })}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ExactRetailMenu;
