import { DietType, Kitchen, PlanType, SubscriptionFrequency, SubscriptionTier } from '@calo/types';
import client from 'lib/client';

export const getDietTypePlans = async (
  kitchen: Kitchen,
  frequency?: SubscriptionFrequency,
  dietType?: DietType,
  subscriptionTier?: SubscriptionTier | null,
  planId?: PlanType,
  foodTypes?: string[]
) => {
  const { data } = await client.get(`/plans/${kitchen}`, {
    params: { frequency, dietType, planId, foodTypes: JSON.stringify(foodTypes), ...(subscriptionTier && { subscriptionTier }) }
  });
  return data.data;
};
export const getKitchenDietTypes = async (kitchen: Kitchen) => {
  const { data } = await client.get(`/diets/${kitchen}`);
  return data.data;
};
