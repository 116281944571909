import { caloTheme } from 'assets/images/theme/calo';

export const styles = {
  infoCard: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: caloTheme.palette.neutral50,
    borderRadius: '8px',
    paddingY: '8px',
    paddingX: '12px',
    marginTop: '-4px',
    marginBottom: '10px',
    marginHorizontal: '0px'
  }
};
