import { Box, Card, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { caloTheme } from 'assets/images/theme/calo';
import Breakdown from './Breakdown';
import Selection from './Selection';
import { usePlanCalculator } from './usePlanCalculator';

export default () => {
  const {
    kitchens,
    selectedKitchen,
    handleKitchenSelection,
    availableDeliveryDays,
    selectedDeliveryDays,
    handleDeliveryDaySelection,
    totalNrOfDeliveries,
    frequencies,
    handleFrequencySelection,
    selectedSubscriptionFrequency,
    diets,
    plans,
    selectedDietType,
    setSelectedDietType,
    selectedPlanId,
    setSelectedPlanId,
    selectedPlan,
    isCustomPlan,
    selectedCustomPlanMeals,
    handleCustomMealPlan,
    subscriptionTier,
    setSubscriptionTier
  } = usePlanCalculator();

  return (
    <Card
      variant="outlined"
      sx={{
        width: 'full',
        mb: '14px',
        border: 'none',
        borderRadius: '8px',
        paddingBottom: '4px',
        [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
          flexDirection: 'column'
        }
      }}
    >
      <Box
        sx={{
          padding: 2
        }}
      >
        <Typography
          variant="h3"
          sx={{
            textAlign: 'left',
            lineHeight: '40px',
            fontFamily: caloTheme.typography.fontFamily,
            fontWeight: 600,
            marginBottom: 2
          }}
        >
          Plans Calculator
        </Typography>
        <Grid2 container spacing={3}>
          <Grid2 xs={7}>
            <Selection
              kitchens={kitchens}
              selectedKitchen={selectedKitchen}
              setKitchen={handleKitchenSelection}
              availableDeliveryDays={availableDeliveryDays}
              selectedDeliveryDays={selectedDeliveryDays}
              handleDeliveryDaySelection={handleDeliveryDaySelection}
              dietTypes={diets.map((d) => d.id)}
              setDietType={setSelectedDietType}
              selectedDietType={selectedDietType}
              frequencies={frequencies}
              selectedFrequency={selectedSubscriptionFrequency}
              setSubscriptionFrequency={handleFrequencySelection}
              availablePlans={plans}
              planTypes={plans.map((p) => p.planId)}
              selectedPlanType={selectedPlanId}
              setPlanType={setSelectedPlanId}
              isCustomPlan={isCustomPlan}
              selectedCustomPlanMeals={selectedCustomPlanMeals}
              handleCustomMealPlan={handleCustomMealPlan}
            />
          </Grid2>
          <Grid2 xs={5}>
            <Breakdown
              plan={selectedPlan}
              selectedMeals={selectedCustomPlanMeals}
              numberOfDays={totalNrOfDeliveries}
              subscriptionTier={subscriptionTier}
              setSubscriptionTier={setSubscriptionTier}
            />
          </Grid2>
        </Grid2>
      </Box>
    </Card>
  );
};
