import {
  CreateSubscriptionReq,
  GetFoodPreferenceReq,
  MacrosPreferencePriority,
  SubscriptionFilters,
  UpdateAllowanceDaysExceptionReq,
  UpdateSubscriptionReq
} from '@calo/dashboard-types';
import {
  DeliveryPreferences,
  Ingredient,
  IngredientCategory,
  NewDeliveryAddress,
  PauseSubscriptionReq,
  Subscription,
  SubscriptionTier,
  UpdateDeliveryAddressReq,
  UpdateSubscriptionPlan
} from '@calo/types';
import { HealthConditions } from 'lib/enums';

import client from 'lib/client';
import { ToastSuccessMsgs } from 'lib/helpers';
import { SubscriptionTransactionHistory } from 'lib/interfaces';
import { HealthConditionNote } from '../views/Nutrition/HealthConditionNotes/HealthConditionNotes';
import mutation from './mutation';

export const updateSubscriptionCognitoUser = async (id: string, values: any) => {
  const { status } = await client.put(`/app-cognito-users/${id}`, {
    ...values
  });
  ToastSuccessMsgs({ status, action: 'User updated successfully' });
};

export const getSubscriptionCognitoUser = async (searchValue: string) => {
  const { data } = await client.get('/app-cognito-users', {
    params: { q: searchValue }
  });
  return data;
};

export const getSubscriptionsStats = async () => {
  const { data } = await client.get('/stats/subscription');
  return data;
};

export const addSubscriptionAddress = async (payload: NewDeliveryAddress & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.post(`/subscriptions/${id}/addresses`, rest);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'new address created' });
};

export const updateSubscriptionAddress = async (payload: UpdateDeliveryAddressReq & { id: string; daid: string }) => {
  const { id, daid, ...rest } = payload;
  const { data, status } = await client.put(`/subscriptions/${id}/addresses/${daid}`, rest);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'Address updated' });
};

export const deleteSubscriptionAddress = async (payload: { id: string; daid: string }) => {
  const { id, daid } = payload;
  const { data, status } = await client.delete(`/subscriptions/${id}/addresses/${daid}`);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'Address deleted' });
};

export const updateSubscriptionDeliveryPreferences = async (
  payload: { deliveryPreferences: DeliveryPreferences } & { id: string }
) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/subscriptions/${id}/delivery-preferences`, rest);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'Delivery Preferences updated' });
};

export const removeSuspension = async (id: string) => {
  const { data, status } = await client.post(`/subscriptions/${id}/remove-suspension`);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'subscriptions Skipped' });
};

export const pauseSubscription = async (payload: PauseSubscriptionReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.post(`/subscriptions/${id}/pause`, rest);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'subscription paused' });
  return data;
};

export const resumeSubscription = async ({ id }: { id: string }) => {
  const { data, status } = await client.post(`/subscriptions/${id}/resume`);
  mutation(['subscriptions', id], {
    ...data,
    pausedAt: undefined,
    unpauseAt: undefined
  });
  ToastSuccessMsgs({ status, action: 'Subscription resumed ' });
  return data;
};

export const clearSubscriptionPause = async ({ id }: { id: string }) => {
  const { data, status } = await client.post(`/subscriptions/${id}/pause/clear`);
  mutation(['subscriptions', id], {
    ...data,
    pausedAt: undefined,
    unpauseAt: undefined
  });
  ToastSuccessMsgs({ status, action: 'Pause schedule cleared ' });
  return data;
};

export const updateSubscription = async (payload: UpdateSubscriptionReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/subscriptions/${id}`, rest);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'Subscription updated' });
  return data;
};

export const updateBlockMeal = async (payload: GetFoodPreferenceReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/subscriptions/${id}/block-meal`, rest);
  console.log('response', data);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'Blocked Meals updated ' });
  return data;
};

export const updateSubscription2 = async (payload: {
  data: UpdateSubscriptionReq & { deliveryDays?: number[] };
  subId: string;
}): Promise<Subscription> => {
  const { subId, ...rest } = payload;
  const { data, status } = await client.put(`/subscriptions/${subId}`, rest.data);
  mutation(['subscriptions', subId], data);

  const fieldsUpdated = Object.keys(rest.data);
  if (fieldsUpdated.length > 0) {
    ToastSuccessMsgs({ status, action: `${fieldsUpdated.join(', ')} updated` });
  }

  if (status !== 200) {
    ToastSuccessMsgs({ status, action: data });
  }

  return data;
};

export const updateSubscriptionPhoneNumber = async (payload: { phoneNumber: string; id: string }) => {
  const { phoneNumber, id } = payload;
  const { data, status } = await client.put(`/subscriptions/${id}/phone-number`, {
    phoneNumber: phoneNumber
  });
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'Phone number updated' });
};

export const updateSubscriptionPlan = async (
  payload: UpdateSubscriptionPlan & {
    id: string;
    tier?: SubscriptionTier;
  }
): Promise<void> => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/subscriptions/${id}/plan`, rest);
  mutation(['subscriptions', id], data);
  ToastSuccessMsgs({ status, action: 'Subscription plan updated' });
};

export const findOnboardingInformation = async (phoneNumber: string) => {
  const { data } = await client.get(`/new-subscriptions`, {
    params: { phoneNumber }
  });
  if (data) {
    mutation(['onboardingInformation', data.id], data);
    return data;
  }
};

export const createSubscription = async (ns: CreateSubscriptionReq) => {
  const { data, status } = await client.post('/subscriptions', ns);
  mutation(['subscriptions', data.id], data);
  ToastSuccessMsgs({ status, action: 'new Subscription created' });
  return data;
};

export const exportSubscriptions = async (filters: SubscriptionFilters) => {
  const { data, status } = await client.get('subscriptions/export', {
    params: filters
  });
  ToastSuccessMsgs({ status, action: 'The exported file sent to your email' });
  return data;
};

export const updateFoodPreference = async (payload: {
  subscriptionId: string;
  allergicIng?: Ingredient[];
  healthConditions?: HealthConditions[];
  notes?: HealthConditionNote[];
  favorite?: string[];
  dislikedIng?: Ingredient[];
  dislikedIngCategory?: IngredientCategory[];
  macrosPreferencePriority?: MacrosPreferencePriority;
}) => {
  const {
    subscriptionId,
    allergicIng,
    healthConditions,
    notes,
    favorite,
    dislikedIng,
    dislikedIngCategory,
    macrosPreferencePriority
  } = payload;
  console.log({ payload });

  const { data, status } = await client.put(`food-preferences/${subscriptionId}`, {
    favouriteFood: favorite,
    ingredientsToAvoid: allergicIng,
    dislikedIngredients: dislikedIng,
    dislikedIngredientsCategory: dislikedIngCategory,
    healthConditions: {
      healthConditions,
      notes
    },
    macrosPreferencePriority
  });
  ToastSuccessMsgs({ status, action: 'Subscription Food Preferences updated' });
  return data;
};

export const getTransactionHistory = async (id: string): Promise<SubscriptionTransactionHistory> => {
  const data = await client.get<SubscriptionTransactionHistory>(`/subscriptions/${id}/payment-balance`);
  return data.data;
};

export const updateAllowanceDays = async (payload: UpdateAllowanceDaysExceptionReq & { id: string }) => {
  const { id, ...rest } = payload;
  const { data, status } = await client.put(`/subscriptions/${id}/allowance-days`, {
    ...rest
  });
  ToastSuccessMsgs({ status, action: 'Allowance days updated' });
  return data;
};

export const getUnpaidDeliveries = async (
  id: string
): Promise<{
  count: number;
  totalUnpaidDeliveriesCost: number;
  unpaidDeliveries: {
    id: string;
    day: string;
  }[];
}> => {
  const data = await client.get<{
    count: number;
    totalUnpaidDeliveriesCost: number;
    unpaidDeliveries: {
      id: string;
      day: string;
    }[];
  }>(`/subscriptions/${id}/unpaid-deliveries`);

  return data.data;
};

export const postSyncRemainingDays = async (id: string) => {
  const data = await client.put<{
    remainingDays: number;
    lastDay: string;
    renewDate: string;
  }>(`/subscriptions/${id}/remaining-days/sync`);
  return data.data;
};
