import { Subscription, UpdateSubscriptionReq } from '@calo/dashboard-types';
import { Brand, FoodPreference, HealthConditionNote, IngredientCategory } from '@calo/types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Typography } from '@mui/material';
import { getListWithParams, updateFoodPreference, updateSubscription } from 'actions';
import { HealthConditions } from 'lib/enums';
import { Ingredient, Options } from 'lib/interfaces';
import { compact, difference, sortBy, startCase, uniqBy } from 'lodash-es';
import React, { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import DislikedIngredients from './DislikedIngredients';
import HealthCondition from './HealthCondition';
import OtherDislikedIngredients from './OtherDislikedIngredients';
import OtherWarningIngredients from './OtherWarningIngredients';
import WarningIngredients from './WarningIngredients';

interface FoodRestrictionsProps {
  foodPref: any;
  selectedCustomer: Subscription & { foodPreference?: FoodPreference };
  refetch: any;
  customerNotes: HealthConditionNote[];
  healthConditionsData: HealthConditions[] | undefined;
  setHealthConditionData: React.Dispatch<React.SetStateAction<HealthConditions[]>>;
  setAllergicIngredients: React.Dispatch<React.SetStateAction<any[]>>;
  allergicIngredients: any[];
  favMealIds: string[];
}

const FoodRestrictions = ({
  refetch,
  selectedCustomer,
  customerNotes,
  favMealIds,
  setAllergicIngredients,
  setHealthConditionData,
  healthConditionsData,
  allergicIngredients,
  foodPref
}: FoodRestrictionsProps) => {
  const [filterName, setFilterName] = useState<string | undefined>();
  const [healthConditionIngredient, setHealthConditionIngredient] = useState<string[]>([]);
  const [allIngredients, setAllIngredients] = useState<Ingredient[]>([]);
  const { mutateAsync: updateMutation } = useMutation(updateSubscription);
  const { mutateAsync: updateAllergicIngMutation } = useMutation(updateFoodPreference);

  const [warningIngredients, setWarningIngredients] = useState<IngredientCategory[]>(
    selectedCustomer.macrosData?.allergicFood || []
  );
  const [dislikedIngredientsCategory, setDislikedIngredientsCategory] = useState<IngredientCategory[]>(
    selectedCustomer?.foodPreference?.dislikedIngredientsCategory || []
  );
  const [dislikedIngredients, setDislikedIngredients] = useState<Ingredient[]>(
    (selectedCustomer?.foodPreference?.dislikedIngredients as Ingredient[]) ?? []
  );
  const [headerFoodRestrictionOpen, setHederFoodRestrictionOpen] = useState<boolean>(false);

  const handleUpdateAllergicFood = async (data: UpdateSubscriptionReq) => {
    if (
      !data.macrosData?.allergicFood?.includes(IngredientCategory.other) &&
      selectedCustomer.macrosData.allergicFood?.includes(IngredientCategory.other)
    ) {
      await updateAllergicIngMutation({
        subscriptionId: selectedCustomer.id,
        allergicIng: [],
        favorite: favMealIds || [],
        healthConditions: healthConditionsData,
        notes: customerNotes
      }).finally(() => {
        setAllergicIngredients([]);
      });
    }
    await updateMutation({
      id: selectedCustomer.id,
      ...data
    });
  };

  const handleUpdateFoodPrefIngredientsToAvoid = async (data: any) => {
    const isOtherAlreadySelected = selectedCustomer.macrosData?.allergicFood?.includes(IngredientCategory.other);
    const isOtherSelected = warningIngredients?.includes(IngredientCategory.other);
    if (isOtherAlreadySelected && !isOtherSelected) {
      await updateAllergicIngMutation({
        subscriptionId: selectedCustomer.id,
        allergicIng: [],
        healthConditions: healthConditionsData,
        notes: customerNotes,
        favorite: favMealIds
      });
      setAllergicIngredients([]);
    } else if (
      isOtherSelected &&
      (difference(foodPref?.ingredientsToAvoid, data).length > 0 ||
        difference(data, foodPref?.ingredientsToAvoid || []).length > 0)
    ) {
      await updateAllergicIngMutation({
        subscriptionId: selectedCustomer.id,
        allergicIng: data,
        healthConditions: healthConditionsData,
        notes: customerNotes,
        favorite: favMealIds
      });
      setAllIngredients(data);
    }
    refetch();
  };

  const { isLoading: LoadingWarningIng } = useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: selectedCustomer.country,
          brand: selectedCustomer.brand || Brand.CALO,
          name: filterName || undefined
        }
      }
    ],
    getListWithParams,
    {
      keepPreviousData: false,
      suspense: false,
      onSuccess: (allIngredientList) => {
        setAllIngredients(allIngredientList?.data);
      }
    }
  );

  useQuery<any, Error, { data: Ingredient[] }>(
    [
      'ingredients',
      {
        filters: {
          country: selectedCustomer.country,
          brand: selectedCustomer.brand || Brand.CALO,
          ids: healthConditionIngredient || undefined
        }
      }
    ],
    getListWithParams,
    {
      enabled: healthConditionIngredient.length > 0,
      keepPreviousData: false,
      onSuccess: (allIngredientList) => {
        if (allIngredientList.data.length > 0) {
          const ingredientSelectedData = allIngredientList.data?.map(
            (ingData) =>
              ingData && {
                category: ingData.category,
                id: ingData.id,
                name: ingData.name,
                slug: ingData.slug,
                internalName: ingData.internalName
              }
          );
          setAllergicIngredients((old) =>
            old ? uniqBy(compact([...old, ...ingredientSelectedData]), 'id') : uniqBy(compact(ingredientSelectedData), 'id')
          );
          const updatedOtherIngredients = allergicIngredients
            ? uniqBy(compact([...allergicIngredients, ...allIngredientList.data.map((ingredient) => ingredient)]), 'id')
            : uniqBy(compact(allIngredientList.data.map((ingredient) => ingredient)), 'id');
          handleUpdateFoodPrefIngredientsToAvoid(updatedOtherIngredients);
          setHealthConditionIngredient([]);
        }
      }
    }
  );

  const options: Options<Ingredient>[] = useMemo(
    () =>
      sortBy(uniqBy([...allIngredients, ...allergicIngredients], 'id'), (ingredient) => `${ingredient.name.en}`)
        .map((ingredient) => ({
          value: ingredient.id,
          data: { ...ingredient },
          label: `${ingredient.name.en} (${ingredient.internalName || ''})`
        }))
        .filter(
          (ingOption) =>
            !allergicIngredients.some((ing) => ing.id === ingOption.value) &&
            !dislikedIngredients.some((ing) => ing.id === ingOption.value)
        ),
    [allIngredients, allergicIngredients, dislikedIngredients]
  );

  const handleChangeDislikedIngCat = async (data: IngredientCategory[]) => {
    const newIng = difference(data, dislikedIngredientsCategory);
    if (newIng.length > 0 && newIng[0] !== IngredientCategory.other && warningIngredients.includes(newIng[0])) {
      toast(`${startCase(newIng[0])} is already added as an allergen ingredient. Remove it to be able to add it as a disliked`, {
        type: 'error',
        autoClose: 2000
      });
      return;
    }
    setDislikedIngredientsCategory(data);
    const emptyDislikedIng =
      dislikedIngredientsCategory.includes(IngredientCategory.other) && !data.includes(IngredientCategory.other);
    await updateAllergicIngMutation({
      subscriptionId: selectedCustomer.id,
      dislikedIngCategory: data,
      dislikedIng: emptyDislikedIng ? [] : dislikedIngredients
    });
    emptyDislikedIng && setDislikedIngredients([]);
    refetch();
  };

  return (
    <>
      <Box
        width={'100%'}
        display={'flex'}
        justifyContent={'space-between'}
        onClick={() => setHederFoodRestrictionOpen(!headerFoodRestrictionOpen)}
      >
        <Typography
          style={{
            fontFamily: 'Roboto',
            fontSize: '19px',
            fontStyle: 'normal',
            fontWeight: 600,
            lineHeight: '23px',
            letterSpacing: '-0.38px'
          }}
        >
          Food Restrictions
        </Typography>
        {headerFoodRestrictionOpen ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
      </Box>

      {/* Health condition pick up */}
      <HealthCondition
        healthConditionsData={healthConditionsData}
        setHealthConditionData={setHealthConditionData}
        setHealthConditionIngredient={setHealthConditionIngredient}
        headerFoodRestrictionOpen={headerFoodRestrictionOpen}
        selectedCustomer={selectedCustomer}
        warningIngredients={warningIngredients}
        setWarningIngredients={setWarningIngredients}
        handleUpdateAllergicFood={handleUpdateAllergicFood}
        updateAllergicIngMutation={updateAllergicIngMutation}
        allergicIngredients={allergicIngredients}
        customerNotes={customerNotes}
        favMealIds={favMealIds}
      />

      {/* Warning pick up */}
      <WarningIngredients
        headerFoodRestrictionOpen={headerFoodRestrictionOpen}
        warningIngredients={warningIngredients}
        setWarningIngredients={setWarningIngredients}
        selectedCustomer={selectedCustomer}
        handleUpdateAllergicFood={handleUpdateAllergicFood}
        dislikedIngredientsCategory={dislikedIngredientsCategory}
      />
      {/* Other Warning Ingredients pick up */}
      {warningIngredients?.includes(IngredientCategory.other) && (
        <OtherWarningIngredients
          headerFoodRestrictionOpen={headerFoodRestrictionOpen}
          warningIngredients={warningIngredients}
          allergicIngredients={allergicIngredients}
          setAllergicIngredients={setAllergicIngredients}
          options={options}
          LoadingWarningIng={LoadingWarningIng}
          handleUpdateFoodPrefIngredientsToAvoid={handleUpdateFoodPrefIngredientsToAvoid}
          setFilterName={setFilterName}
        />
      )}

      {/* Disliked ingredients */}
      <DislikedIngredients
        headerFoodRestrictionOpen={headerFoodRestrictionOpen}
        dislikedIngredientsCategory={dislikedIngredientsCategory}
        setDislikedIngredientsCategory={setDislikedIngredientsCategory}
        selectedCustomer={selectedCustomer}
        handleChangeDislikedIngCat={handleChangeDislikedIngCat}
      />
      {/* Other Disliked Ingredients*/}
      {(dislikedIngredientsCategory?.includes(IngredientCategory.other) || dislikedIngredients.length > 0) && (
        <OtherDislikedIngredients
          headerFoodRestrictionOpen={headerFoodRestrictionOpen}
          dislikedIngredients={dislikedIngredients}
          setDislikedIngredients={setDislikedIngredients}
          options={options}
          LoadingWarningIng={LoadingWarningIng}
          setFilterName={setFilterName}
          updateAllergicIngMutation={updateAllergicIngMutation}
          selectedCustomer={selectedCustomer}
          refetch={refetch}
        />
      )}
    </>
  );
};
export default FoodRestrictions;
