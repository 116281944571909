import { TableCell, TableFooter, TableRow } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Dictionary, Nutrition } from '@calo/types';
import { FoodComponent } from 'lib/interfaces';
import { round, sumBy } from 'lodash-es';
import { MacrosOffset } from 'lib/enums';
import { ComponentService } from '@calo/services';
import { LinkedComponent } from '@calo/dashboard-types';

interface CustomizedFoodTableFooterProps {
  isPreBuildCustom?: boolean;
  selectedComponents: LinkedComponent[];
  allComponents: Dictionary<FoodComponent>;
  Nutrition: any;
  isChild: boolean;
}

const calculateMacrosPercentage = (totalWeight: number, totalCalories: number, offset: number) => {
  return round(((totalWeight * offset) / totalCalories) * 100, 1);
};

const CustomizedFoodTableFooter = ({
  isPreBuildCustom,
  selectedComponents,
  allComponents,
  Nutrition,
  isChild
}: CustomizedFoodTableFooterProps) => {
  const handleSumMacrosData = (allComponents: Dictionary<FoodComponent>, macrosType: Nutrition) => {
    return round(
      sumBy(selectedComponents, (fc) => {
        const component = allComponents[fc.id];
        if (!component) return 0;
        const componentWeight = ComponentService.calculateComponentWeight(
          component.cups,
          component.measurementUnit,
          component.weight ?? 1,
          fc?.quantity || 0
        );
        return isChild
          ? ((component?.macros[macrosType] || 0) / (component?.weight || 1)) * componentWeight
          : (component?.macros[macrosType] || 0) * componentWeight;
      })
    );
  };

  const totalCalories = handleSumMacrosData(allComponents, 'cal' as any);
  const totalProteinWeight = handleSumMacrosData(allComponents, Nutrition.protein);
  const totalCarbsWeight = handleSumMacrosData(allComponents, Nutrition.carbs);
  const totalFatWeight = handleSumMacrosData(allComponents, Nutrition.fat);

  const proteinPercentage = calculateMacrosPercentage(totalProteinWeight, totalCalories, MacrosOffset.protein);
  const carbsPercentage = calculateMacrosPercentage(totalCarbsWeight, totalCalories, MacrosOffset.carbs);
  const fatPercentage = calculateMacrosPercentage(totalFatWeight, totalCalories, MacrosOffset.fat);

  return (
    <TableFooter>
      <TableRow>
        <TableCell
          colSpan={isPreBuildCustom ? 7 : 6}
          sx={{ borderBottom: 0, pb: 1, borderTop: '1px solid ' + caloTheme.palette.neutral100 }}
        >
          {' '}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 0,
            pb: 1,
            fontSize: '16px',
            borderTop: '1px solid ' + caloTheme.palette.neutral100,
            color: caloTheme.palette.black
          }}
        >
          {totalCalories}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 0,
            pb: 1,
            fontSize: '16px',
            borderTop: '1px solid ' + caloTheme.palette.neutral100,
            color: caloTheme.palette.black
          }}
        >
          {totalProteinWeight}
          {!isChild && Boolean(proteinPercentage) && `(${proteinPercentage}%)`}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 0,
            pb: 1,
            fontSize: '16px',
            borderTop: '1px solid ' + caloTheme.palette.neutral100,
            color: caloTheme.palette.black
          }}
        >
          {totalCarbsWeight}
          {!isChild && Boolean(carbsPercentage) && `(${carbsPercentage}%)`}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 0,
            pb: 1,
            fontSize: '16px',
            borderTop: '1px solid ' + caloTheme.palette.neutral100,
            color: caloTheme.palette.black
          }}
        >
          {totalFatWeight}
          {!isChild && Boolean(fatPercentage) && `(${fatPercentage}%)`}
        </TableCell>
        <TableCell
          sx={{
            borderBottom: 0,
            pb: 1,
            fontSize: '16px',
            borderTop: '1px solid ' + caloTheme.palette.neutral100,
            color: caloTheme.palette.black
          }}
        >
          {handleSumMacrosData(allComponents, Nutrition.fiber)}
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};

export default CustomizedFoodTableFooter;
