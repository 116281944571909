import { Modal, Button as OButton, Select } from 'components';
import { getAvailableMealSizes } from 'lib/helpers';
import { Food } from 'lib/interfaces';
import { forwardRef, useEffect, useState } from 'react';
import CustomizeMacrosPopUp from 'views/ChefPlayground/ExactFood/CustomizeFoodMacrosPopUp';

interface NewSizeModalProps {
  selectedFood: Food;
  foodWithAllSizes: Food[];
  setSelectedFood: (food: Food) => void;
  setFoodWithAllSizes: (food: Food[]) => void;
  close: () => void;
}

const NewSizeModal = forwardRef(
  ({ selectedFood, foodWithAllSizes, setSelectedFood, setFoodWithAllSizes, close }: NewSizeModalProps, ref) => {
    const [inputSize, setInputSize] = useState('');
    const [selectedSize, setSelectedSize] = useState('');
    const [isDuplicate, setIsDuplicate] = useState(false);

    useEffect(() => {
      const sizeExists = foodWithAllSizes.some((food) => food.size === selectedSize.toUpperCase());
      setIsDuplicate(sizeExists);
    }, [selectedSize, foodWithAllSizes]);

    return (
      <Modal ref={ref}>
        <div className="flex-row justify-between items-center mb-3">
          <h1 className="text-3xl font-bold">Add Meal Size</h1>
          <OButton icon="fas fa-times fa-lg" onClick={() => close()} className="ml-4 w-4 h-auto border-none" />
        </div>
        <div className="flex flex-col">
          <Select
            label="Meal Size"
            value={selectedSize}
            onInputChange={(d: any) => {
              if (d) {
                setInputSize(d);
              }
            }}
            onChange={(data: any) => setSelectedSize(data.value)}
            options={getAvailableMealSizes(['XS', 'S', 'M', 'L', 'XL', inputSize], foodWithAllSizes).map((type) => ({
              value: type.toUpperCase(),
              label: type.toUpperCase()
            }))}
          />
          <CustomizeMacrosPopUp
            food={{ ...selectedFood, positionIndex: 0 }}
            isDuplicate={isDuplicate}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            selectedFood={selectedFood}
            setSelectedFood={setSelectedFood}
            foodWithAllSizes={foodWithAllSizes}
            setFoodWithAllSizes={setFoodWithAllSizes}
            close={close}
          />
        </div>
      </Modal>
    );
  }
);

export default NewSizeModal;
