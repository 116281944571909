import { caloTheme } from 'assets/images/theme/calo';
import { InputValueRestrictionType, Routes } from 'lib/enums';
import { getComponentWeight, isNumberInputValid } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent, FoodComponentWithQuantity } from 'lib/interfaces';
import { keyBy, round } from 'lodash-es';
import { getChildCompsWithQuantity, purchasingCostInfo } from 'views/Food/helpers';

import { LinkedComponent, MenuFoodComponent, Permission } from '@calo/dashboard-types';
import { CustomMealCategory, Dictionary, Nutrition } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import { IconButton, TableCell, TextField, Typography } from '@mui/material';

import InputThemeProvider from '../InputThemeProvider';
import PrebuiltCustomCells from './PrebuiltCustomCells';

interface FoodComponentPickerProps {
  childComp: boolean;
  isDisabled: boolean;
  selectedComponents: MenuFoodComponent[];
  allComponents: Dictionary<FoodComponent>;
  onChange: (rows: MenuFoodComponent[]) => void;
  component: FoodComponentWithQuantity;
  isPreBuildCustom?: boolean;
  setTotalCostPerUnit: (cost: number) => void;
  setTotalCost: (cost: number) => void;
  index?: number;
}

const childCompsWithQuantity = (
  allComponents: Dictionary<FoodComponent>,
  childComponents: Dictionary<FoodComponent>,
  component: LinkedComponent
) =>
  allComponents[component.id]?.childComponents?.length
    ? getChildCompsWithQuantity(childComponents, allComponents, component)
    : [];

const FoodComponentRow = ({
  component,
  allComponents,
  selectedComponents,
  isDisabled,
  isPreBuildCustom,
  onChange,
  setTotalCost,
  setTotalCostPerUnit,
  index
}: FoodComponentPickerProps) => {
  const roles = useUserRoles();

  const isDeleteButtonActive = !isDisabled;
  const mappedComponent = allComponents[component.id];
  const isPrototype = mappedComponent?.prototype;

  const componentWeight = getComponentWeight(allComponents, component);

  const childComponents = (component.childComponents ?? []) as FoodComponentWithQuantity[];
  const keyedChildComponents = keyBy(childComponents, 'id');

  const handleChange = (id: string, quantity: number) => {
    if (!isNumberInputValid(quantity, InputValueRestrictionType.quantity)) {
      return;
    }

    const i = selectedComponents.findIndex((r) => r.id === id);
    onChange([...selectedComponents.slice(0, i), { ...selectedComponents[i], quantity }, ...selectedComponents.slice(i + 1)]);
  };

  let totalCost = 0;
  let totalCostPerUnit = 0;

  for (const component of selectedComponents) {
    if (allComponents[component.id]) {
      const { totalPurchasingCost } = purchasingCostInfo(
        allComponents[component.id].ingredients,
        childCompsWithQuantity(allComponents, keyedChildComponents, component) ?? [],
        allComponents[component.id].cookedRawFactor ?? 1,
        allComponents[component.id].weight ?? 1
      );
      const componentWeight = getComponentWeight(allComponents, component);
      const costPerGram = totalPurchasingCost;
      totalCost += costPerGram * componentWeight;
      totalCostPerUnit += costPerGram;
    }
  }

  setTotalCost(totalCost);
  setTotalCostPerUnit(totalCostPerUnit);

  const handleComponentCostPerUnit = () => {
    const currentComponent = allComponents[component.id];
    if (currentComponent) {
      const { totalPurchasingCost } = purchasingCostInfo(
        allComponents[component.id].ingredients,
        childCompsWithQuantity(allComponents, keyedChildComponents, component) ?? [],
        allComponents[component.id].cookedRawFactor ?? 1,
        allComponents[component.id].weight ?? 1
      );
      return totalPurchasingCost;
    }
    return 0;
  };

  const handleRemoveFoodComponent = (removedId: string) => {
    const newList: MenuFoodComponent[] = [...selectedComponents];
    const food = newList.filter((r) => r.id !== removedId);
    onChange(food);
  };

  const navigateToComponentHandler = () => {
    const currentComponent = allComponents[component.id];
    const url = (isPrototype ? Routes.playgroundComponentSlug : Routes.foodComponentSlug).replace(':slug', currentComponent.slug);
    window.open(url, '_blank');
  };

  const handleMacrosData = (allComponents: Dictionary<FoodComponent>, macrosType: Nutrition, rowId: string, weight: number) => {
    return round((allComponents[rowId]?.macros[macrosType] || 0) * weight, 6);
  };

  return (
    <>
      <TableCell sx={{ p: 0, width: '50px' }}>
        <IconButton
          data-test={`handleRemoveFoodComponent#${index}`}
          disabled={!isDeleteButtonActive}
          onClick={() => handleRemoveFoodComponent(component.id)}
        >
          <Iconify
            color={isDeleteButtonActive ? caloTheme.palette.neutral900 : caloTheme.palette.neutral600}
            width={24}
            icon="uil:trash"
          />
        </IconButton>
      </TableCell>
      <TableCell sx={{ pl: 0, fontSize: '16px' }}>
        <Typography sx={{ position: 'relative', display: 'inline' }}>
          {isPrototype && (
            <Typography sx={{ position: 'absolute', right: 0, top: -15, fontSize: '12px', color: caloTheme.palette.primary500 }}>
              (Draft)
            </Typography>
          )}
          {allComponents[component.id]?.name.en && allComponents[component.id]?.name.en}
        </Typography>
      </TableCell>
      <InputThemeProvider>
        {isPreBuildCustom ? (
          <PrebuiltCustomCells
            component={component}
            quantity={
              allComponents[component.id]?.sections?.find((section) => section.category === CustomMealCategory.meal)?.quantity ??
              1
            }
            handleChange={handleChange}
            isDisabled={isDisabled}
          />
        ) : (
          <TableCell>
            <TextField
              data-test={`componentQuantityInput#${index}`}
              type="number"
              value={component.quantity}
              onChange={(e) => handleChange(component.id, parseFloat(e.target.value))}
              error={!component.quantity}
              disabled={isDisabled}
              inputProps={{ style: { borderRadius: 8 }, datatest: `foodComponentQuantityInput#${index}` }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: caloTheme.palette.primary500
                  }
                }
              }}
            />
          </TableCell>
        )}
      </InputThemeProvider>

      <TableCell sx={{ fontSize: '16px' }}>{allComponents[component.id]?.measurementUnit}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{componentWeight}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, 'cal' as any, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.protein, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.carbs, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.fat, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.fiber, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{handleComponentCostPerUnit()}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{round(handleComponentCostPerUnit() * componentWeight, 6)}</TableCell>
      <TableCell sx={{ p: 0 }}>
        <IconButton disabled={!roles.includes(Permission.VIEW_FOOD_COMPONENTS)} onClick={navigateToComponentHandler}>
          <Iconify
            color={isDeleteButtonActive ? caloTheme.palette.neutral900 : caloTheme.palette.neutral600}
            width={24}
            icon="ph:arrow-right"
          />
        </IconButton>
      </TableCell>
    </>
  );
};
export default FoodComponentRow;
