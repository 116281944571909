import { Subscription } from '@calo/dashboard-types';
import { FoodPreference, IngredientCategory } from '@calo/types';
import { Box, MenuItem, Stack, TextField } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { difference, startCase } from 'lodash-es';
import { toast } from 'react-toastify';
import FoodRestrictionItem from './FoodRestrictionItem';

interface WarningIngredientsProps {
  headerFoodRestrictionOpen: boolean;
  warningIngredients: IngredientCategory[];
  setWarningIngredients: React.Dispatch<React.SetStateAction<IngredientCategory[]>>;
  selectedCustomer: Subscription & { foodPreference?: FoodPreference };
  handleUpdateAllergicFood: (data: any) => void;
  dislikedIngredientsCategory: IngredientCategory[];
}

const WarningIngredients = ({
  headerFoodRestrictionOpen,
  warningIngredients,
  setWarningIngredients,
  selectedCustomer,
  handleUpdateAllergicFood,
  dislikedIngredientsCategory
}: WarningIngredientsProps) => {
  const handleChangeWarningIng = (data: IngredientCategory[]) => {
    const newIng = difference(data, warningIngredients);
    if (newIng.length > 0 && newIng[0] !== IngredientCategory.other && dislikedIngredientsCategory.includes(newIng[0])) {
      toast(`${startCase(newIng[0])} is already added as a disliked ingredient. Remove it to be able to add it as an allergen`, {
        type: 'error',
        autoClose: 2000
      });
      return;
    }
    setWarningIngredients(data);
    handleUpdateAllergicFood({
      ...selectedCustomer,
      macrosData: {
        ...selectedCustomer.macrosData,
        allergicFood: data
      }
    });
  };

  return (
    <Box style={{ marginTop: '4px', display: headerFoodRestrictionOpen ? 'flex' : 'none', flexDirection: 'column' }}>
      <Stack>
        <TextField
          select
          placeholder={'Search Ingredients'}
          label="Warning Ingredients"
          value={warningIngredients}
          SelectProps={{
            multiple: true,
            value: warningIngredients || [],
            onChange: (data) => handleChangeWarningIng(data.target.value as IngredientCategory[])
          }}
          InputProps={{ inputProps: { style: { borderRadius: 8, width: 'full' } }, style: { borderRadius: 8 } }}
        >
          {Object.values(IngredientCategory)
            .filter((r) => (warningIngredients ? !warningIngredients.includes(r) : r))
            .map((row) => (
              <MenuItem key={row} value={row}>
                {startCase(row)}
              </MenuItem>
            ))}
        </TextField>
        <Box sx={{ paddingY: 1, paddingX: '4px', display: selectedCustomer.macrosData!.allergicFood ? 'relative' : 'none' }}>
          <Stack
            sx={{
              border: 0,
              borderColor: caloTheme.palette.neutral100,
              borderRadius: '8px',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap'
            }}
          >
            {warningIngredients &&
              warningIngredients.length > 0 &&
              warningIngredients.map((allergicIng: any) =>
                FoodRestrictionItem({
                  keyProp: `${allergicIng.id}-${allergicIng.internalName}-${allergicIng.slug}`,
                  text: `${startCase(allergicIng.toLowerCase())}`,
                  onClick: () => (
                    setWarningIngredients(() => warningIngredients.filter((wi) => wi !== allergicIng)),
                    handleUpdateAllergicFood({
                      ...selectedCustomer,
                      macrosData: {
                        ...selectedCustomer.macrosData,
                        allergicFood: warningIngredients.filter((wi) => wi !== allergicIng) || []
                      }
                    })
                  )
                })
              )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default WarningIngredients;
