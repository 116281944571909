import { useCallback, useEffect, useMemo, useState } from 'react';

import { DietType, FoodType, Kitchen, PlanType, SubscriptionFrequency, SubscriptionTier } from '@calo/types';

import { getDietTypePlans, getKitchenDietTypes } from 'actions/index';
import queryClient from 'lib/queryClient';

export function usePlanCalculator() {
  const [selectedKitchen, setKitchen] = useState(Kitchen.BH1);
  const kitchens = useMemo(() => Object.values(Kitchen).filter((k) => !k.includes('000')), []);
  const handleKitchenSelection = useCallback((kitchen: Kitchen) => {
    setKitchen(kitchen);
  }, []);

  const [availableDeliveryDays, _] = useState([0, 1, 2, 3, 4, 5, 6]);
  const [selectedDeliveryDays, setSelectedDeliveryDays] = useState([0, 1, 2, 3, 4]);

  const [diets, setDietTypes] = useState<any[]>([]);
  const [selectedDietType, setSelectedDietType] = useState<DietType | undefined>(undefined);

  const [frequencies, setFrequencies] = useState<SubscriptionFrequency[]>([]);
  const [selectedSubscriptionFrequency, setSubscriptionFrequency] = useState<SubscriptionFrequency | undefined>(
    SubscriptionFrequency.monthly
  );
  const handleFrequencySelection = useCallback((frequency: SubscriptionFrequency) => {
    setSubscriptionFrequency(frequency);
  }, []);

  const [totalNrOfDeliveries, setTotalNrOfDeliveries] = useState(selectedDeliveryDays.length);

  const handleDeliveryDaySelection = (day: number) => {
    if (selectedDeliveryDays.includes(day)) {
      setSelectedDeliveryDays(selectedDeliveryDays.filter((d) => d !== day));
    } else {
      setSelectedDeliveryDays([...selectedDeliveryDays, day]);
    }
  };

  useEffect(() => {
    let numberOfDays = 0;
    switch (selectedSubscriptionFrequency) {
      case SubscriptionFrequency.weekly:
      default:
        numberOfDays = selectedDeliveryDays.length;
        break;
      case SubscriptionFrequency.monthly:
        numberOfDays = selectedDeliveryDays.length * 4;
        break;
      case SubscriptionFrequency.quarterly:
        numberOfDays = selectedDeliveryDays.length * 4 * 3;
        break;
    }
    setTotalNrOfDeliveries(numberOfDays);
  }, [selectedDeliveryDays, selectedSubscriptionFrequency]);

  const [plans, setPlans] = useState<any[]>([]);
  const [selectedPlanId, setSelectedPlanId] = useState<PlanType | undefined>(undefined);

  const [selectedPlan, setSelectedPlan] = useState(undefined);

  const [isCustomPlan, setIsCustomPlan] = useState(false);
  const [selectedCustomPlanMeals, setSelectedCustomPlanMeals] = useState(
    Object.fromEntries(Object.values(FoodType).map((key) => [key, 0]))
  );

  const [subscriptionTier, setSubscriptionTier] = useState<SubscriptionTier | null>(null);
  const [foodTypes, setFoodTypes] = useState<string[]>([]);

  const listDietTypes = async (kitchen: Kitchen) => {
    const response = await queryClient.fetchQuery(
      [`kitchen/${kitchen}/dietTypes`],
      async () => {
        const data = await getKitchenDietTypes(kitchen);
        return data;
      },
      { staleTime: 10_000 }
    );
    return response;
  };

  const listPlans = async (
    kitchen: Kitchen,
    frequency?: SubscriptionFrequency,
    dietType?: DietType,
    subscriptionTier?: SubscriptionTier | null,
    planId?: PlanType,
    foodTypes?: string[]
  ) => {
    const response = await queryClient.fetchQuery(
      ['plans', kitchen, frequency, dietType, subscriptionTier, planId, foodTypes],
      async () => {
        const data = await getDietTypePlans(kitchen, frequency, dietType, subscriptionTier, planId, foodTypes);
        return data;
      },
      { staleTime: 10_000 }
    );
    return response;
  };

  async function fetchPlans(subscriptionTier?: SubscriptionTier | null) {
    let plans = await listPlans(selectedKitchen, selectedSubscriptionFrequency, selectedDietType, subscriptionTier);
    plans = plans.filter((p) => p.frequency === selectedSubscriptionFrequency);
    setPlans(plans);
  }

  async function fetchCustomPlan(foodTypes: string[]) {
    const plans = await listPlans(
      selectedKitchen,
      selectedSubscriptionFrequency,
      selectedDietType,
      subscriptionTier,
      PlanType.custom,
      foodTypes
    );
    if (plans.length > 0) {
      setSelectedPlan({ ...plans[0], foodTypes: Object.keys(plans[0].foodTypePrices) });
    }
  }

  useEffect(() => {
    async function fetchDietTypes(kitchen) {
      const dietTypes = await listDietTypes(kitchen);
      setDietTypes(dietTypes);
    }

    fetchDietTypes(selectedKitchen);
    reset();
  }, [selectedKitchen]);

  useEffect(() => {
    if (selectedDietType) {
      setFrequencies(Object.values(SubscriptionFrequency));
    }
  }, [selectedDietType]);

  useEffect(() => {
    if (selectedDietType && selectedSubscriptionFrequency) {
      setSelectedPlanId(undefined);
      setSelectedPlan(undefined);
      setSubscriptionTier(null);
      setPlans([]);
      fetchPlans();
    }
  }, [selectedDietType, selectedSubscriptionFrequency]);

  useEffect(() => {
    if (selectedPlanId) {
      const plan = plans.find((p) => p.planId === selectedPlanId && p.frequency === selectedSubscriptionFrequency);

      if (selectedPlanId === PlanType.custom) {
        if (!isCustomPlan) {
          setIsCustomPlan(true);
          setSelectedPlan({ ...plan, foodTypes: Object.keys(plan.foodTypePrices) });
          setSelectedCustomPlanMeals(Object.fromEntries(Object.values(FoodType).map((key) => [key, 0])));
        }
      } else {
        setIsCustomPlan(false);
        setSelectedPlan(plan);
      }
    }
  }, [selectedPlanId, plans]);

  useEffect(() => {
    if (selectedPlanId === PlanType.custom) {
      const newFoodTypes: string[] = [];
      for (const key in selectedCustomPlanMeals) {
        if (selectedCustomPlanMeals[key] > 0) {
          for (let i = 0; i < selectedCustomPlanMeals[key]; i++) {
            newFoodTypes.push(key);
          }
        }
      }

      setFoodTypes(newFoodTypes);

      if (newFoodTypes.length > 0) {
        setSelectedPlan(undefined);
        fetchCustomPlan(newFoodTypes);
      }
    }
  }, [selectedCustomPlanMeals]);

  // Effect to fetch plans when subscriptionTier changes
  useEffect(() => {
    if (subscriptionTier) {
      setSelectedPlan(undefined);
      if (selectedPlanId === PlanType.custom && foodTypes.length > 0) {
        fetchCustomPlan(foodTypes);
      } else {
        fetchPlans(subscriptionTier);
      }
    }
  }, [subscriptionTier]);

  const handleCustomMealPlan = (meal: FoodType, action: 'add' | 'remove') => {
    let clone = selectedCustomPlanMeals;
    if (action === 'add') {
      clone = {
        ...clone,
        [meal]: clone[meal] + 1
      };
    } else {
      clone = {
        ...clone,
        [meal]: clone[meal] - 1 < 0 ? 0 : clone[meal] - 1
      };
    }

    setSelectedCustomPlanMeals(clone);
  };

  const reset = () => {
    setDietTypes([]);
    setSelectedDietType(undefined);

    setFrequencies([]);
    setSubscriptionFrequency(undefined);

    setPlans([]);
    setSelectedPlanId(undefined);
    setSelectedPlan(undefined);

    setIsCustomPlan(false);
    setSelectedCustomPlanMeals(Object.fromEntries(Object.values(FoodType).map((key) => [key, 0])));
  };

  return {
    kitchens,
    selectedKitchen,
    handleKitchenSelection,
    availableDeliveryDays,
    selectedDeliveryDays,
    totalNrOfDeliveries,
    handleDeliveryDaySelection: handleDeliveryDaySelection,
    diets: diets,
    selectedDietType: selectedDietType,
    setSelectedDietType: setSelectedDietType,
    frequencies: frequencies,
    selectedSubscriptionFrequency: selectedSubscriptionFrequency,
    handleFrequencySelection: handleFrequencySelection,
    plans: plans,
    setSelectedPlanId: setSelectedPlanId,
    selectedPlanId: selectedPlanId,
    selectedPlan: selectedPlan,
    isCustomPlan: isCustomPlan,
    selectedCustomPlanMeals: selectedCustomPlanMeals,
    handleCustomMealPlan: handleCustomMealPlan,
    subscriptionTier: subscriptionTier,
    setSubscriptionTier: setSubscriptionTier
  };
}
