import { getPrototypeFoodCustomMacros } from 'actions/calculatePrototypeFoodCustomMacros';
import { Food, FoodWithPosition } from 'lib/interfaces';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { CustomMealCategory, Macros } from '@calo/types';
import { Box } from '@mui/material';

import { CustomizeFoodMacrosResultCard } from './CustomizeFoodMacrosResultsCard';
import { CustomMacrosGoalsCard } from './CustomMacrosGoalsCard';
import { useCustomizeFoodMacrosForm } from 'views/ChefPlayground/ExactFood/CustomizeFoodMacrosPopUp/CustomizeFoodMacrosForm';

interface CustomizeMacrosPopUpProps {
  food: FoodWithPosition;
  isDuplicate: boolean;
  selectedSize: string;
  setSelectedSize: (size: string) => void;
  selectedFood: Food;
  setSelectedFood: (food: Food) => void;
  foodWithAllSizes: Food[];
  setFoodWithAllSizes: (food: Food[]) => void;
  close: () => void;
}

const isAthleteFood = (food) => {
  return food.category === CustomMealCategory.athlete;
};

const CustomizeMacrosPopUp = ({
  food,
  selectedSize,
  setSelectedSize,
  selectedFood,
  setSelectedFood,
  foodWithAllSizes,
  setFoodWithAllSizes,
  close
}: CustomizeMacrosPopUpProps) => {
  const [newMacros, setNewMacros] = useState<Macros>();
  const [foodTargetMacros, setFoodTargetMacros] = useState<Macros>();
  const [calculatedFood, setCalculatedFood] = useState<FoodWithPosition>();
  const { isLoading, refetch, isFetching, isFetched } = useQuery<any, Error, any>(
    ['athlete/custom', { macros: newMacros }],
    () => getPrototypeFoodCustomMacros(food.id, newMacros!),
    {
      enabled: !!newMacros,
      onSuccess: (data) => {
        setCalculatedFood(data);
      }
    }
  );

  const onSubmit = (macros: Macros) => {
    setNewMacros((prev) => ({ ...prev, ...macros }));
    setOpenResults(true);
  };
  const { values, handleChange, handleBlur, errors, touched, submitForm, setFieldValue } = useCustomizeFoodMacrosForm({
    onSuccess: onSubmit
  });

  const [openResult, setOpenResults] = useState(false);

  useEffect(() => {
    if (food?.category === CustomMealCategory.athlete && food?.metadata) {
      // @ts-ignore
      const foodMetadata = JSON.parse(food?.metadata);
      setFoodTargetMacros(foodMetadata.targetMacros);
      setOpenResults(true);
    }
  }, [food.id]);

  useEffect(() => {
    if (newMacros) {
      refetch();
    }
  }, [newMacros]);

  return (
    <>
      <CustomMacrosGoalsCard
        isLoading={isLoading || isFetching}
        targetMacros={foodTargetMacros}
        submitForm={submitForm}
        values={values}
        handleChange={handleChange}
        handleBlur={handleBlur}
        errors={errors}
        touched={touched}
        setFieldValue={setFieldValue}
        isFetched={isFetched}
      />
      {openResult && (calculatedFood || isAthleteFood(food)) && !isLoading && !isFetching && (
        <Box sx={{ mt: 4 }}>
          <CustomizeFoodMacrosResultCard
            newMacros={newMacros}
            food={(calculatedFood ?? food) as unknown as Food}
            positionIndex={food.positionIndex}
            selectedFood={selectedFood}
            setSelectedFood={setSelectedFood}
            foodWithAllSizes={foodWithAllSizes}
            setFoodWithAllSizes={setFoodWithAllSizes}
            selectedSize={selectedSize}
            setSelectedSize={setSelectedSize}
            close={close}
            submitForm={submitForm}
          />
        </Box>
      )}
    </>
  );
};

export default CustomizeMacrosPopUp;
