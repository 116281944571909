// @ts-nocheck

import { useEffect, useMemo, useState } from 'react';

import cx from 'classnames';
import { sortBy } from 'lodash-es';

import { RetailMenuFoodItem } from '@calo/dashboard-types';

import { RetailMenuItemTag } from '@calo/types';
import { selectedFoodData } from 'actions';
import Input from 'components/Input';
import { handleSearch } from 'lib/helpers';
import { MenuFood } from 'lib/interfaces';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery } from 'react-query';
import Button from '../Button';
import Modal from '../Modal';
import SelectMUI from '../MUI/SelectMUI';
import Select from '../Select';
import FoodModifiers from './FoodModifiers';
import { useFoodModifiers } from './useFoodModifiers';

interface RetailPickerProps {
  retailRef: any;
  retailList: MenuFood[];
  retailItem: RetailMenuFoodItem;
  existRetail: RetailMenuFoodItem[];
  setFilterName?: React.Dispatch<React.SetStateAction<string | undefined>>;
  handleSelectedRetailItem?: (id: string) => void;
  AddToTable: (value: RetailMenuFoodItem) => void;
  isLoading: boolean;
  onClose: () => void;
}

const retailTagsOptions = Object.values(RetailMenuItemTag).map((item) => ({ label: item, value: item }));

const RetailPicker = ({
  AddToTable,
  retailRef,
  retailList,
  retailItem,
  existRetail,
  setFilterName,
  handleSelectedRetailItem,
  isLoading,
  onClose
}: RetailPickerProps) => {
  const [currentRetailItem, setCurrentRetailItem] = useState<RetailMenuFoodItem | undefined>(retailItem);

  const options = useMemo(
    () =>
      sortBy(retailList, (f) => `${f.name.en}-${f.size}`).map((food) => ({
        name: food.name.en,
        value: food.id,
        label: `${food.name.en} (${food.size})`
      })),
    [retailList, existRetail]
  );

  const { data: currentFood } = useQuery(
    ['currentComponentList', currentRetailItem?.id],
    () => selectedFoodData(currentRetailItem?.id),
    {
      enabled: !!currentRetailItem?.id
    }
  );

  const handleDataChange = () => {
    if (currentRetailItem && currentRetailItem.priority && currentRetailItem.id) {
      retailRef.current?.close();

      const newRetailItem = {
        ...currentRetailItem,
        foodModifiers:
          currentRetailItem.foodModifiers?.map((modifier) => ({
            ...modifier,
            componentOptions: modifier.componentOptions?.map((option) => ({ id: option.id, quantity: option.quantity })) || []
          })) || []
      };
      AddToTable(newRetailItem);
    }
  };

  useEffect(() => {
    setCurrentRetailItem(retailItem);
  }, [retailItem]);

  const {
    setCurrentComponentName,
    isLoadingFoodComponents,
    componentOptions,
    foodComponentList,
    handleAddModifier,
    handleRemoveModifier,
    handleComponentOptionsChange,
    error
  } = useFoodModifiers(currentRetailItem, setCurrentRetailItem, currentFood);

  return (
    <div>
      <Modal ref={retailRef} onClose={onClose}>
        <div className="flex flex-col max-h-screen overflow-y-auto">
          {!retailItem.id && (
            <Select
              label="Item"
              onChange={(data: any) => {
                setCurrentRetailItem({ ...currentRetailItem, id: data.value, priority: +existRetail.length + 1 });
                handleSelectedRetailItem && handleSelectedRetailItem(data.value);
              }}
              onInputChange={(data: string, action: any) =>
                setFilterName && handleSearch({ text: data, action, name: setFilterName })
              }
              placeholder={'Search...'}
              data-test="addon-picker-addon-select"
              options={options}
              isLoading={isLoading}
              value={currentRetailItem?.id}
            />
          )}
          {currentFood && currentFood.name && (
            <div className="mb-3">
              <b>{currentFood.name.en}</b>
            </div>
          )}
          <Input
            label="Price"
            type="number"
            value={currentRetailItem?.retailPrice}
            onChange={(e) =>
              setCurrentRetailItem({
                ...currentRetailItem,
                retailPrice: +e.target.value
              })
            }
          />

          <SelectMUI
            isMulti
            label="Retail Tag"
            options={retailTagsOptions}
            value={currentRetailItem?.retailTagList ? currentRetailItem.retailTagList : []}
            onChange={(selectedOptions) => {
              // Get the selected option values
              const selectedValues = selectedOptions ? selectedOptions.map((option) => option.value) : [];

              // Preserve existing tags not present in retailTagsOptions
              const allTags = [...new Set([...(currentRetailItem?.retailTagList || []), ...selectedValues])];
              setCurrentRetailItem({
                ...currentRetailItem,
                retailTagList: allTags
              });
            }}
          />

          <div className="flex flex-row mb-3"></div>

          <div className="flex  ">
            <div className="field mr-5 flex flex-col ">
              <label className="label">Start Date</label>
              <div className="flex flex-row">
                <DatePicker
                  selected={currentRetailItem?.startDate ? new Date(currentRetailItem.startDate) : null}
                  onChange={(date) => setCurrentRetailItem({ ...currentRetailItem, startDate: date })}
                  dateFormat="yyyy/MM/dd"
                  className="input"
                  placeholderText="Select start date"
                />
                {currentRetailItem?.startDate && (
                  <Button
                    danger
                    icon="fas fa-trash"
                    onClick={() => setCurrentRetailItem({ ...currentRetailItem, startDate: null })}
                    data-test="remove-start-date-button"
                    className="-ml-8"
                  />
                )}
              </div>
            </div>
            <div className="field flex flex-col ">
              <label className="label">End Date</label>
              <div className="flex flex-row">
                <DatePicker
                  selected={currentRetailItem?.endDate ? new Date(currentRetailItem.endDate) : null}
                  onChange={(date) => setCurrentRetailItem({ ...currentRetailItem, endDate: date })}
                  dateFormat="yyyy/MM/dd"
                  className="input"
                  placeholderText="Select end date"
                />
                {currentRetailItem?.endDate && (
                  <Button
                    danger
                    icon="fas fa-trash"
                    onClick={() => setCurrentRetailItem({ ...currentRetailItem, endDate: null })}
                    data-test="remove-end-date-button"
                    className="-ml-8"
                  />
                )}
              </div>
            </div>
            <div className="field mt-8 ml-8 flex flex-row w-32  justify-between mt-3">
              <label className="label">Disabled</label>
              <label
                className={cx(' cursor-pointer h-7 w-12 p-1 rounded-full', {
                  'toggle-left bg-red-500': currentRetailItem?.disabled,
                  'toggle-right bg-gray-500': !currentRetailItem?.disabled
                })}
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={currentRetailItem?.disabled}
                  onChange={() => setCurrentRetailItem({ ...currentRetailItem, disabled: !currentRetailItem?.disabled })}
                />
                <div
                  className={cx('h-5 w-5 rounded-full bg-white transition-all right-0', {
                    'ml-5': !currentRetailItem?.disabled
                  })}
                ></div>
              </label>
            </div>
            <div className="field mt-8 ml-8 flex flex-row w-32  justify-between mt-3">
              <label className="label">Swap Available</label>
              <label
                className={cx(' cursor-pointer h-7 w-12 p-1 rounded-full', {
                  'toggle-left bg-green-500': currentRetailItem?.swapAvailable,
                  'toggle-right bg-gray-500': !currentRetailItem?.swapAvailable
                })}
              >
                <input
                  type="checkbox"
                  className="hidden"
                  checked={currentRetailItem?.swapAvailable}
                  onChange={() =>
                    setCurrentRetailItem({ ...currentRetailItem, swapAvailable: !currentRetailItem?.swapAvailable })
                  }
                />
                <div
                  className={cx('h-5 w-5 rounded-full bg-white transition-all right-0', {
                    'ml-5': !currentRetailItem?.swapAvailable
                  })}
                ></div>
              </label>
            </div>
          </div>

          {/* Use the FoodModifiers Component */}
          <FoodModifiers
            currentRetailItem={currentRetailItem}
            setCurrentRetailItem={setCurrentRetailItem}
            currentFood={currentFood}
            setCurrentComponentName={setCurrentComponentName}
            isLoadingFoodComponents={isLoadingFoodComponents}
            componentOptions={componentOptions}
            foodComponentList={foodComponentList}
            handleAddModifier={handleAddModifier}
            handleRemoveModifier={handleRemoveModifier}
            handleComponentOptionsChange={handleComponentOptionsChange}
            error={error}
          />
          {error.map((err) => (
            <div className="text-red-500">{err}</div>
          ))}
          <Button
            warning
            content="Confirm"
            disabled={!currentRetailItem?.id || !!error.length}
            onClick={handleDataChange}
            data-test="addon-picker-confirm-button"
          />
        </div>
      </Modal>
    </div>
  );
};

export default RetailPicker;
