import { caloTheme } from 'assets/images/theme/calo';
import { TableCell, TableHead, TableRow } from '@mui/material';

interface CustomizedFoodTableHeadProps {
  isPreBuildCustom?: boolean;
}

const CustomizedFoodTableHead = ({ isPreBuildCustom }: CustomizedFoodTableHeadProps) => {
  return (
    <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
      <TableRow>
        <TableCell
          colSpan={2}
          sx={{
            borderRadius: '8px 0 0 8px',
            border: 0,
            fontWeight: 600,
            lineHeight: '14px',
            fontSize: '12px',
            minWidth: '250px'
          }}
        >
          Component Name
        </TableCell>
        {isPreBuildCustom && (
          <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '150px' }}>
            Times Portioned
          </TableCell>
        )}
        <TableCell />
        <TableCell
          sx={{
            border: 0,
            fontWeight: 600,
            lineHeight: '14px',
            fontSize: '12px',
            minWidth: isPreBuildCustom ? '100px' : '150px'
          }}
        >
          Quantity
        </TableCell>
        <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>Unit</TableCell>
        <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>Weight</TableCell>
        <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
          Calories
        </TableCell>
        <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>
          Proteins
        </TableCell>
        <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>Carbs</TableCell>
        <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>Fats</TableCell>
        <TableCell sx={{ border: 0, fontWeight: 600, lineHeight: '14px', fontSize: '12px', minWidth: '100px' }}>Fibers</TableCell>
      </TableRow>
    </TableHead>
  );
};
export default CustomizedFoodTableHead;
