import { getListWithParams } from 'actions';
import { FoodComponent } from 'lib/interfaces';
import { uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';

export const useFoodModifiers = (currentRetailItem, setCurrentRetailItem, currentFood) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [currentComponentName, setCurrentComponentName] = useState<string>('salad');
  const foodComponentSample = {
    name: 'salad',
    country: 'BH',
    brand: 'CALO',
    kitchen: 'BH001'
  };

  const { data: foodComponentData, isLoading: isLoadingFoodComponents } = useQuery<any, Error>(
    [
      'food-components',
      {
        filters: {
          name: currentComponentName,
          country: foodComponentSample?.country,
          brand: foodComponentSample?.brand,
          kitchen: foodComponentSample?.kitchen
        },
        limit: 50
      }
    ],
    getListWithParams,
    {
      enabled: !!foodComponentSample?.name,
      keepPreviousData: true
    }
  );

  const componentOptions = useMemo(
    () =>
      foodComponentData?.data
        ? foodComponentData.data.map((component) => ({
            value: component.id,
            label: component.name.en
          }))
        : [],
    [foodComponentData]
  );

  const { data: foodComponentList } = useQuery<any, Error, { data: FoodComponent[] }>(
    [
      'food-components',
      {
        filters: {
          ids: currentFood?.components.map((comp) => comp.id) || []
        }
      }
    ],
    getListWithParams,
    {
      enabled: !!currentFood?.components,
      keepPreviousData: true
    }
  );

  const handleAddModifier = () => {
    // @ts-ignore
    setCurrentRetailItem((prevItem) => {
      if (prevItem) {
        const newModifier = {
          name: { en: '', ar: '' },
          type: '',
          componentOptions: [],
          otherOptions: []
        };
        const updatedModifiers = [...(prevItem.foodModifiers || []), newModifier];
        return { ...prevItem, foodModifiers: updatedModifiers };
      }
      return prevItem;
    });
  };

  const handleRemoveModifier = (index: number) => {
    setCurrentRetailItem((prevItem) => {
      if (prevItem) {
        const updatedModifiers = prevItem.foodModifiers?.filter((_, modIndex) => modIndex !== index) || [];
        return { ...prevItem, foodModifiers: updatedModifiers };
      }
      return prevItem;
    });
  };

  const handleComponentOptionsChange = (selectedOptions, modifier, action) => {
    const restModifiers = currentRetailItem?.foodModifiers?.filter((mod) => mod.name.en !== modifier.name.en) || [];
    switch (action.action) {
      //eslint-disable-next-line no-case-declarations
      case `select-option`:
        // eslint-disable-next-line no-case-declarations
        const componentOptionsSelect = uniqBy(
          [
            ...modifier.componentOptions,
            ...selectedOptions
              //@ts-ignore
              .map((option) => ({ id: option.value, name: { en: option.label } }))
          ],
          'id'
        );
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            //@ts-ignore
            ...restModifiers,
            {
              ...modifier,
              componentOptions: componentOptionsSelect
            }
          ]
        });
        break;
      //eslint-disable-next-line no-case-declarations
      case 'remove-value':
        // eslint-disable-next-line no-case-declarations
        const componentOptionsRemove = modifier.componentOptions.filter(
          (option) => !action.removedValue.value.includes(option.id)
        );
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            //@ts-ignore
            ...restModifiers,
            {
              ...modifier,
              componentOptions: componentOptionsRemove
            }
          ]
        });
        break;
      //eslint-disable-next-line no-case-declarations
      case 'clear':
        setCurrentRetailItem({
          ...currentRetailItem,
          foodModifiers: [
            //@ts-ignore
            ...restModifiers,
            {
              ...modifier,
              componentOptions: []
            }
          ]
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const newErrors: string[] = [];
    const modifierNames = currentRetailItem?.foodModifiers?.map((mod) => mod.name.en) || [];
    const duplicateNames = modifierNames.filter((name, index) => modifierNames.indexOf(name) !== index);
    if (duplicateNames.length > 0) {
      newErrors.push('Two modifiers share the same English name');
    }

    const emptyComponentOptions = currentRetailItem?.foodModifiers?.some((mod) => mod.componentOptions.length === 0);
    if (emptyComponentOptions) {
      newErrors.push('A modifier has been added but the componentOptions is empty');
    }

    const replaceModifierWithoutSource = currentRetailItem?.foodModifiers?.some(
      (mod) => mod.type === 'REPLACE' && !mod.sourceComponentID
    );
    if (replaceModifierWithoutSource) {
      newErrors.push('A modifier with type "replace" has been added but does not have a source component');
    }

    const emptyNameEn = currentRetailItem?.foodModifiers?.some((mod) => mod.name.en.trim() === '');
    if (emptyNameEn) {
      newErrors.push('A modifier has been added but the English name is empty');
    }

    const emptyNameAr = currentRetailItem?.foodModifiers?.some((mod) => mod.name.ar.trim() === '');
    if (emptyNameAr) {
      newErrors.push('A modifier has been added but the Arabic name is empty');
    }

    setErrors(newErrors);
  }, [currentRetailItem?.foodModifiers]);

  return {
    currentComponentName,
    setCurrentComponentName,
    foodComponentSample,
    foodComponentData,
    isLoadingFoodComponents,
    componentOptions,
    foodComponentList,
    handleAddModifier,
    handleRemoveModifier,
    handleComponentOptionsChange,
    error: errors
  };
};
