import { LinkedComponent, MenuFoodComponent, Permission } from '@calo/dashboard-types';
import { CustomMealCategory, Dictionary, Nutrition } from '@calo/types';
import { Icon as Iconify } from '@iconify/react';
import { IconButton, TableCell, TextField, Typography, Stack } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { caloTheme } from 'assets/images/theme/calo';
import { InputValueRestrictionType } from 'lib/enums';
import { getComponentWeight, isNumberInputValid } from 'lib/helpers';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { round } from 'lodash-es';

import InputThemeProvider from 'components/MUI/InputThemeProvider';
import PrebuiltCustomCells from './PrebuiltCustomCells';

interface FoodComponentPickerProps {
  childComp: boolean;
  isDisabled: boolean;
  selectedComponents: MenuFoodComponent[];
  allComponents: Dictionary<FoodComponent>;
  onChange: (rows: MenuFoodComponent[]) => void;
  component: LinkedComponent;
  isPreBuildCustom?: boolean;
  setTotalCostPerUnit?: (cost: number) => void;
  setTotalCost?: (cost: number) => void;
  index?: number;
}

const getQuantityConstrains = (component) => {
  if (!component.quantityConstraints) {
    return (
      <Stack>
        <Typography sx={{ margin: 0 }}> Meal or Component tags not found ! </Typography>
      </Stack>
    );
  }
  return (
    <Stack>
      <Typography sx={{ margin: 0 }}>
        Type: {component.quantityConstraints?.type} - onMacros: {component.quantityConstraints?.macros}
      </Typography>
      <Typography sx={{ margin: 0 }}>
        MinPercentage: {component.quantityConstraints?.minPercentage}% - MaxPercentage:{' '}
        {component.quantityConstraints?.maxPercentage}%
      </Typography>
      <Typography sx={{ margin: 0 }}>
        MinGrams: {component.quantityConstraints?.min} - MaxGrams: {component.quantityConstraints?.max}
      </Typography>
    </Stack>
  );
};

const CustomizedFoodComponentRow = ({
  isDisabled,
  selectedComponents,
  onChange,
  allComponents,
  component,
  isPreBuildCustom,
  index
}: FoodComponentPickerProps) => {
  const roles = useUserRoles();
  const componentWeight = getComponentWeight(allComponents, component);

  const handleChange = (id: string, quantity: number) => {
    if (!isNumberInputValid(quantity, InputValueRestrictionType.quantity)) {
      return;
    }

    const i = selectedComponents.findIndex((r) => r.id === id);
    onChange([...selectedComponents.slice(0, i), { ...selectedComponents[i], quantity }, ...selectedComponents.slice(i + 1)]);
  };

  const handleRemoveFoodComponent = (removedId: string) => {
    const newList: MenuFoodComponent[] = [...selectedComponents];
    const food = newList.filter((r) => r.id !== removedId);
    onChange(food);
  };
  const handleMacrosData = (allComponents: Dictionary<FoodComponent>, macrosType: Nutrition, rowId: string, weight: number) => {
    return round((allComponents[rowId]?.macros[macrosType] || 0) * weight, 6);
  };

  const isRemoveComponentButtonActive = roles.includes(Permission.UPDATE_FOOD) && !isDisabled;

  return (
    <>
      <TableCell sx={{ p: 0, width: '50px' }}>
        <IconButton
          data-test={`handleRemoveFoodComponent#${index}`}
          disabled={!isRemoveComponentButtonActive}
          onClick={() => handleRemoveFoodComponent(component.id)}
        >
          <Iconify
            color={isRemoveComponentButtonActive ? caloTheme.palette.neutral900 : caloTheme.palette.neutral600}
            width={24}
            icon="uil:trash"
          />
        </IconButton>
      </TableCell>
      <TableCell sx={{ pl: 0, fontSize: '16px' }}>
        {allComponents[component.id]?.name.en && allComponents[component.id]?.name.en}
      </TableCell>
      <TableCell sx={{ pl: 0, pr: 0, fontSize: '16px' }}>
        <Tooltip title={getQuantityConstrains(component)} placement="top" arrow>
          <Iconify
            color={isRemoveComponentButtonActive ? caloTheme.palette.neutral900 : caloTheme.palette.neutral600}
            width={24}
            icon="carbon:warning"
          />
        </Tooltip>
      </TableCell>
      <InputThemeProvider>
        {isPreBuildCustom ? (
          <>
            <PrebuiltCustomCells
              index={index}
              component={component}
              isDisabled={isDisabled}
              customQuantity={
                allComponents[component.id]?.sections?.find((section) => section.category === CustomMealCategory.meal)
                  ?.quantity ?? 1
              }
              handleChange={handleChange}
            />
          </>
        ) : (
          <TableCell>
            <TextField
              data-test={`componentQuantityInput#${index}`}
              type="number"
              value={component.quantity}
              onChange={(e) => handleChange(component.id, parseFloat(e.target.value))}
              error={!component.quantity}
              disabled={isDisabled}
              inputProps={{ style: { borderRadius: 8 }, datatest: `foodComponentQuantityInput#${index}` }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&.Mui-focused fieldset': {
                    borderColor: caloTheme.palette.primary500
                  }
                }
              }}
            />
          </TableCell>
        )}
      </InputThemeProvider>

      <TableCell sx={{ fontSize: '16px' }}>{allComponents[component.id]?.measurementUnit}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>{componentWeight}</TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, 'cal' as any, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.protein, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.carbs, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.fat, component.id, componentWeight)}
      </TableCell>
      <TableCell sx={{ fontSize: '16px' }}>
        {handleMacrosData(allComponents, Nutrition.fiber, component.id, componentWeight)}
      </TableCell>
    </>
  );
};
export default CustomizedFoodComponentRow;
