import { Macros } from '@calo/types';
import LoadingButton from '@mui/lab/LoadingButton';
import { InputLabel, Stack, TextField, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { useEffect } from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import * as React from 'react';
interface CustomMacrosGoalsCardProps {
  isLoading: boolean;
  targetMacros?: Macros;
  submitForm: () => void;
  values: Macros;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: {
    (e: React.FocusEvent<any, Element>): void;
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  };
  errors: FormikErrors<Macros>;
  touched: FormikTouched<Macros>;
  setFieldValue: (field: string, value: any) => void;
  isFetched: boolean;
}
const CustomMacrosGoalsCard = ({
  isLoading,
  targetMacros,
  submitForm,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  touched,
  errors,
  isFetched
}: CustomMacrosGoalsCardProps) => {
  useEffect(() => {
    if (targetMacros) {
      setFieldValue('protein', targetMacros.protein);
      setFieldValue('carbs', targetMacros.carbs);
      setFieldValue('fat', targetMacros.fat);
    }
  }, [targetMacros]);

  useEffect(() => {
    const calories = values.protein * 4 + values.carbs * 4 + values.fat * 9;
    setFieldValue('cal', calories);
  }, [values.protein, values.carbs, values.fat]);

  return (
    <Stack>
      <Stack sx={{ mt: 1 }} direction={'row'} gap={4} width={'100%'}>
        <Stack direction="column" sx={{ width: '300%' }}>
          <InputLabel
            sx={{
              mb: '2px',
              fontSize: '12px',
              fontWeight: 400,
              color: errors.cal ? caloTheme.palette.red500 : caloTheme.palette.neutral800
            }}
          >
            Enter calories (Kcal)
          </InputLabel>
          <TextField
            disabled={true}
            value={values?.cal}
            name="cal"
            onChange={handleChange}
            onBlur={touched.cal ? handleBlur('cal') : (_) => {}}
            type="number"
            sx={{
              width: '100%'
            }}
            error={!!errors.cal}
          />
          {errors.cal && (
            <Typography sx={{ color: caloTheme.palette.red, fontSize: '12px', fontWeight: 400 }}>{errors.cal}</Typography>
          )}
        </Stack>

        <Stack direction="column" sx={{ width: '300%' }}>
          <InputLabel
            sx={{
              mb: '2px',
              fontSize: '12px',
              fontWeight: 400,
              color: errors.protein ? caloTheme.palette.red500 : caloTheme.palette.neutral800
            }}
          >
            Enter Protein (g)
          </InputLabel>
          <TextField
            value={values?.protein}
            name="protein"
            onChange={handleChange}
            onBlur={handleBlur('protein')}
            type="number"
            sx={{
              width: '100%'
            }}
            error={!!errors.protein}
          />
          {errors.protein && touched.protein && (
            <Typography sx={{ color: caloTheme.palette.red, fontSize: '12px', fontWeight: 400 }}>{errors.protein}</Typography>
          )}
        </Stack>
      </Stack>
      <Stack sx={{ mt: 1 }} direction={'row'} gap={4} width={'100%'}>
        <Stack direction="column" sx={{ width: '300%' }}>
          <InputLabel
            sx={{
              mb: '2px',
              fontSize: '12px',
              fontWeight: 400,
              color: errors.carbs ? caloTheme.palette.red500 : caloTheme.palette.neutral800
            }}
          >
            Enter Carbs (g)
          </InputLabel>
          <TextField
            value={values?.carbs}
            name="carbs"
            onChange={handleChange}
            onBlur={touched.carbs ? handleBlur('carbs') : () => {}}
            type="number"
            sx={{
              width: '100%'
            }}
            error={!!errors.carbs}
          />
          {errors.carbs && touched.carbs && (
            <Typography sx={{ color: caloTheme.palette.red, fontSize: '12px', fontWeight: 400 }}>{errors.carbs}</Typography>
          )}
        </Stack>

        <Stack direction="column" sx={{ width: '300%' }}>
          <InputLabel
            sx={{
              mb: '2px',
              fontSize: '12px',
              fontWeight: 400,
              color: errors.fat ? caloTheme.palette.red500 : caloTheme.palette.neutral800
            }}
          >
            Enter fat (g)
          </InputLabel>
          <TextField
            value={values?.fat}
            name="fat"
            onChange={handleChange}
            onBlur={touched.fat ? handleBlur('fat') : () => {}}
            type="number"
            sx={{
              width: '100%'
            }}
            error={!!errors.fat}
          />
          {errors.fat && touched.fat && (
            <Typography sx={{ color: caloTheme.palette.red, fontSize: '12px', fontWeight: 400 }}>{errors.fat}</Typography>
          )}
        </Stack>
      </Stack>
      <Stack sx={{ width: '100%', mt: 2 }} justifyContent="center" alignItems="center">
        {!isFetched && (
          <LoadingButton
            data-test="calculate-button"
            variant="contained"
            aria-label="calculate"
            sx={{
              boxShadow: 'none',
              borderRadius: '8px',
              padding: '10px 16px 10px 16px',
              backgroundColor: caloTheme.palette.primary500,
              '&:hover': {
                backgroundColor: caloTheme.palette.primary600,
                boxShadow: 'none'
              },
              [caloTheme.breakpoints.down(caloTheme.breakpoints.values.lg)]: {
                justifyItems: 'center',
                margin: 'auto',
                width: 'auto'
              }
            }}
            onClick={() => submitForm()}
            loading={isLoading}
          >
            <Typography sx={{ color: caloTheme.palette.white, fontWeight: 600, fontSize: '18px' }}>Calculate</Typography>
          </LoadingButton>
        )}
      </Stack>
    </Stack>
  );
};

export default CustomMacrosGoalsCard;
