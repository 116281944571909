import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Stack, Typography } from '@mui/material';
import { styles } from './styles';

interface ComponentCookedRawFactorProps {
  cookedRawFactorInfo: string;
  handleClose: () => void;
}

const ComponentCookedRawFactorInfo = ({ cookedRawFactorInfo, handleClose }: ComponentCookedRawFactorProps) => {
  return (
    <Stack sx={styles.infoCard}>
      <Stack>
        {cookedRawFactorInfo.split(',').map((info, index) => (
          <Typography key={index} sx={{ fontSize: '14px' }}>
            {info}
          </Typography>
        ))}
      </Stack>
      <IconButton
        onClick={handleClose}
        sx={{
          alignSelf: 'flex-start'
        }}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export default ComponentCookedRawFactorInfo;
