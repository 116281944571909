import { Permission } from '@calo/dashboard-types';
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { FoodComponent } from 'lib/interfaces';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { styles } from './styles';

const FoodComponentListCard = ({ isLoading, components }: { isLoading: boolean; components: FoodComponent[] | undefined }) => {
  const userRoles = useUserRoles();

  return (
    <Card variant="outlined" sx={styles.card}>
      <TableContainer>
        <Table sx={{ width: '100%' }}>
          <TableHead sx={{ backgroundColor: caloTheme.palette.neutral50, border: 0 }}>
            <TableRow>
              <TableCell sx={styles.tableCell}>Name</TableCell>
              <TableCell sx={styles.tableCell}>Raw Weight</TableCell>
              <TableCell sx={styles.tableCell}>Cal</TableCell>
              <TableCell sx={styles.tableCell}>Protein</TableCell>
              <TableCell sx={styles.tableCell}>Carbs</TableCell>
              <TableCell sx={styles.tableCell}>Fat</TableCell>
              <TableCell sx={styles.tableCell}>Cost</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {components?.map((component, index) => (
              <TableRow key={component.id} sx={{ borderBottom: '1px solid' + caloTheme.palette.neutral100 }}>
                <TableCell sx={{ fontSize: '14px' }}>
                  {userRoles.includes(Permission.VIEW_INGREDIENT) ? (
                    <Link data-test={`foodComponentRow#${index}`} to={Routes.foodComponentSlug.replace(':slug', component.slug)}>
                      {component.name?.en}
                    </Link>
                  ) : (
                    `${component.name?.en}`
                  )}
                </TableCell>
                <TableCell sx={{ fontSize: '14px' }}>{component.weight}</TableCell>
                <TableCell sx={{ fontSize: '14px' }}>{component.macros?.cal}</TableCell>
                <TableCell sx={{ fontSize: '14px' }}>{component.macros?.protein}</TableCell>
                <TableCell sx={{ fontSize: '14px' }}>{component.macros?.carbs}</TableCell>
                <TableCell sx={{ fontSize: '14px' }}>{component.macros?.fat}</TableCell>
                <TableCell sx={{ fontSize: '14px' }}>{component.cost}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!isLoading && isEmpty(components) && <Typography sx={{ textAlign: 'center' }}>No Ingredients Found</Typography>}
    </Card>
  );
};

export default FoodComponentListCard;
