import { IngredientCategory } from '@calo/types';
import { Autocomplete, Box, CircularProgress, Stack, TextField } from '@mui/material';
import { caloTheme } from 'assets/images/theme/calo';
import { Ingredient, Options } from 'lib/interfaces';
import { startCase } from 'lodash-es';
import React from 'react';
import FoodRestrictionItem from './FoodRestrictionItem';

interface OtherWarningIngredientsProps {
  warningIngredients: IngredientCategory[];
  headerFoodRestrictionOpen: boolean;
  allergicIngredients: any[];
  setAllergicIngredients: React.Dispatch<React.SetStateAction<any[]>>;
  options: Options<Ingredient>[];
  LoadingWarningIng: boolean;
  handleUpdateFoodPrefIngredientsToAvoid: (data: any) => void;
  setFilterName: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const OtherWarningIngredients = ({
  warningIngredients,
  headerFoodRestrictionOpen,
  allergicIngredients,
  setAllergicIngredients,
  options,
  LoadingWarningIng,
  handleUpdateFoodPrefIngredientsToAvoid,
  setFilterName
}: OtherWarningIngredientsProps) => {
  return (
    <>
      {warningIngredients?.includes(IngredientCategory.other) && (
        <Box style={{ marginTop: '4px', display: headerFoodRestrictionOpen ? 'flex' : 'none', flexDirection: 'column' }}>
          <Stack sx={{ my: 1, pt: 1 }}>
            <Autocomplete
              style={{ borderRadius: 4 }}
              multiple
              renderTags={(value) =>
                value.map((option, index) => (
                  <div key={index} style={{ display: 'none' }}>
                    {option.label}
                  </div>
                ))
              }
              onChange={(event, newValue, r) => {
                if (r === 'selectOption') {
                  // @ts-ignore
                  setAllergicIngredients((old) =>
                    old
                      ? // @ts-ignore
                        uniq(compact([...old, ...newValue.map((ingredient) => ingredient.data)]))
                      : // @ts-ignore
                        uniq(newValue.map((ing) => ing.data))
                  );
                  // @ts-ignore
                  handleUpdateFoodPrefIngredientsToAvoid(
                    // @ts-ignore
                    uniq(compact([...allergicIngredients, ...newValue.map((ingredient) => ingredient.data)]))
                  );
                }
              }}
              defaultValue={[]}
              options={options}
              loading={LoadingWarningIng}
              clearIcon={null}
              onInputChange={(d, text) => {
                setFilterName(text);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Other Ingredients"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {LoadingWarningIng ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    )
                  }}
                />
              )}
            />

            <Box sx={{ padding: '4px' }}>
              <Stack
                sx={{
                  border: 0,
                  borderColor: caloTheme.palette.neutral100,
                  borderRadius: '8px',
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap'
                }}
              >
                {allergicIngredients &&
                  allergicIngredients.length > 0 &&
                  allergicIngredients.map((allergicIng: any) =>
                    FoodRestrictionItem({
                      keyProp: `${allergicIng.id}-${allergicIng.internalName}-${allergicIng.slug}`,
                      text: `${startCase(allergicIng.name.en.toLowerCase())} (${startCase(allergicIng.internalName)})`,
                      onClick: () => (
                        handleUpdateFoodPrefIngredientsToAvoid(allergicIngredients.filter((al) => al.id !== allergicIng.id)),
                        setAllergicIngredients(() => allergicIngredients.filter((al) => al.id !== allergicIng.id))
                      )
                    })
                  )}
              </Stack>
            </Box>
          </Stack>
        </Box>
      )}
    </>
  );
};

export default OtherWarningIngredients;
