import { AddressType, Country, NewDeliveryAddress } from '@calo/types';
import { useFormik } from 'formik';
import { object, string } from 'yup';

type DeliveryAddress = { placeId?: string } & NewDeliveryAddress;

export default (onSubmit: (values: DeliveryAddress) => any, deliveryAddress?: NewDeliveryAddress) =>
  useFormik<DeliveryAddress>({
    initialValues: {
      lat: 26.222917,
      lng: 50.580958,
      country: Country.BH,
      notes: '',
      name: '',
      region: '',
      city: '',
      district: '',
      street: '',
      building: '',
      postalCode: '',
      type: AddressType.home,
      ...deliveryAddress
    },

    validationSchema: object({
      country: string().trim().required('Country is required'),
      postalCode: string()
        .trim()
        .uppercase()
        .when('country', {
          is: Country.GB,
          // eslint-disable-next-line unicorn/no-thenable
          then: (schema) =>
            schema
              .required('Postal code is required')
              .max(8, 'Invalid postcode')
              .matches(
                /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])) [0-9][A-Za-z]{2})$/,
                'Invalid postcode'
              ),
          otherwise: (schema) => schema.optional().nullable()
        }),
      street: string().trim().required('Street is required'),
      building: string().trim().required('Building is required')
    }),

    onSubmit: async (values) => {
      try {
        await onSubmit(values);
      } catch (error) {
        console.log(error);
      }
    }
  });
