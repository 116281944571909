import { Route, Switch } from 'react-router-dom';

import { Permission } from '@calo/dashboard-types';
import { Routes } from 'lib/enums';
import { useUserRoles } from 'lib/hooks';
import { ComponentList, ExactFoodComponent, NewFoodComponent } from './ComponentPlayground';
import { ExactFood, FoodList, NewFood } from './FoodPlayground';
import { ExactIngredient, NewIngredient } from './IngredientPlayground';
import IngredientList from './IngredientPlayground/IngredientList';

const ChefPlayGround = () => {
  const roles = useUserRoles();

  return (
    <Switch>
      {roles.includes(Permission.VIEW_PROTOTYPE_FOOD_LIST) && (
        <Route key={Routes.playgroundFoodList} exact path={Routes.playgroundFoodList} component={FoodList} />
      )}
      {roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT_LIST) && (
        <Route key={Routes.playgroundComponentList} exact path={Routes.playgroundComponentList} component={ComponentList} />
      )}

      <Route key={Routes.playgroundIngredientList} exact path={Routes.playgroundIngredientList} component={IngredientList} />

      {roles.includes(Permission.VIEW_PROTOTYPE_FOOD_LIST_BY_SLUG) && (
        <Route key={Routes.playgroundFoodSlug} exact path={Routes.playgroundFoodSlug} component={ExactFood} />
      )}
      {roles.includes(Permission.CREATE_PROTOTYPE_FOOD) && (
        <Route key={Routes.playgroundNewFood} exact path={Routes.playgroundNewFood} component={NewFood} />
      )}
      {roles.includes(Permission.CREATE_PROTOTYPE_COMPONENT) && (
        <Route key={Routes.playgroundNewComponent} exact path={Routes.playgroundNewComponent} component={NewFoodComponent} />
      )}
      {roles.includes(Permission.VIEW_PROTOTYPE_COMPONENT) && (
        <Route key={Routes.playgroundComponentSlug} exact path={Routes.playgroundComponentSlug} component={ExactFoodComponent} />
      )}
      {roles.includes(Permission.CREATE_PROTOTYPE_INGREDIENT) && (
        <Route key={Routes.playgroundNewIngredient} exact path={Routes.playgroundNewIngredient} component={NewIngredient} />
      )}
      {roles.includes(Permission.VIEW_PROTOTYPE_INGREDIENT) && (
        <Route key={Routes.playgroundIngredientSlug} exact path={Routes.playgroundIngredientSlug} component={ExactIngredient} />
      )}
    </Switch>
  );
};

export default ChefPlayGround;
