import { useFormik } from 'formik';
import { findKey, isNumber } from 'lodash';

import { CreateFoodComponentReq, FoodComponentStatus, MeasurementUnit } from '@calo/dashboard-types';
import { Brand, Country, Kitchen } from '@calo/types';

import { encodeHtmlSymbols } from 'lib/helpers';
import { BaseOmit, FoodComponent } from 'lib/interfaces';

export default (onSubmit: (values: CreateFoodComponentReq) => any) =>
  useFormik<Omit<FoodComponent, BaseOmit>>({
    initialValues: {
      name: {
        en: '',
        ar: ''
      },
      internalName: '',
      macros: {
        cal: 0,
        carbs: 0,
        fat: 0,
        fiber: 0,
        protein: 0
      },
      micronutrients: {
        addedSugar: 0,
        cholesterol: 0,
        saturatedFats: 0,
        sodium: 0,
        totalSugar: 0,
        transFats: 0
      },
      weight: 1,
      cost: 1,
      ingredients: [],
      measurementUnit: MeasurementUnit.g,
      purchasingCost: 0,
      cookedRawFactor: 1,
      tags: [],
      slug: '',
      cookingStation: [],
      country: Country.BH,
      brand: Brand.CALO,
      kitchen: Kitchen.BH1,
      childComponents: [],
      status: findKey(FoodComponentStatus, (enumValue) => enumValue === FoodComponentStatus.IDEATION) as FoodComponentStatus
    },
    validate: (values: Omit<FoodComponent, BaseOmit>) => {
      const errors: any = {};
      if (!values.name!.en) {
        errors.name = {
          ...errors.name,
          en: true
        };
      }

      if (!values.name!.ar) {
        errors.name = {
          ...errors.name,
          ar: true
        };
      }

      if (!values.internalName || values.internalName.trim() === '') {
        errors.internalName = true;
      }

      if (!isNumber(values.macros!.cal)) {
        errors.macros = {
          ...errors.macros,
          cal: true
        };
      }
      if (!isNumber(values.macros!.protein)) {
        errors.macros = {
          ...errors.macros,
          protein: true
        };
      }
      if (!isNumber(values.macros!.carbs)) {
        errors.macros = {
          ...errors.macros,
          carbs: true
        };
      }
      if (!isNumber(values.macros!.fat)) {
        errors.macros = {
          ...errors.macros,
          fat: true
        };
      }

      if (!isNumber(values.micronutrients!.addedSugar)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          addedSugar: true
        };
      }
      if (!isNumber(values.micronutrients!.cholesterol)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          cholesterol: true
        };
      }
      if (!isNumber(values.micronutrients!.saturatedFats)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          saturatedFats: true
        };
      }
      if (!isNumber(values.micronutrients!.sodium)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          sodium: true
        };
      }
      if (!isNumber(values.micronutrients!.totalSugar)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          totalSugar: true
        };
      }
      if (!isNumber(values.micronutrients!.transFats)) {
        errors.micronutrients = {
          ...errors.micronutrients,
          transFats: true
        };
      }

      if (!values.cost) {
        errors.cost = true;
      }

      if (!values.kitchen) {
        errors.kitchen = true;
      }
      if (values.sections) {
        for (const section of values.sections) {
          if (section.quantity <= 0) {
            errors[section.category] = true;
          }
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      const { purchasingCost: _, ...rest } = values;
      if (rest.method) {
        rest.method = rest.method.map((step) => ({
          ...step,
          description: encodeHtmlSymbols(step.description)
        }));
      }
      try {
        await onSubmit(rest);
      } catch (error) {
        console.log(error);
      }
    }
  });
